import React, { useEffect, useState } from 'react';
//Material UI
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItem from '@mui/material/ListItem';
import { useNavigate } from 'react-router-dom';
//Material UI icons
import Badge from '@mui/material/Badge';
import IconNotifications from '../../../../static/icons/navBarIcons/IconNotifications.js';
//Static Files
import useStyles from './ModalNotificationStyle';
import { useAuth } from 'Contexts/AuthContext';
import {
  getUserNotifications,
  editNotificationData
} from 'Utilities/Firebase/NotificationData';
import Notification from 'Helpers/Notification';
import {
  notificationConfig,
  notificationToDoc
} from 'components/Admin/Notifications/NotificationConfig';

// NotificationMsg {
//   date: unix,
//   type: string,
//   read: boolean,
//   document: {
//     name: string,
//     documentId: documentID,
//   },
//   notifier: {
//     id: id UserData,
//     name: String,
//     lastName: String,
//   }
// },

let Signature = [];
const maxBadgeNumber = 5;
const milisecondsInSecond = 1000;
const milisecondsInMinute = 60000;
const milisecondsInHour = 3600000;
const milisecondsInDay = 86400000;
const milisecondsInMonth = 2628000000;
const emptyBadgeNum = 0;

const primary = '#F77417';
const secondary = '#00173E';

function SimpleDialog(props) {
  const { onClose, selectedValue, userNotifications, open } = props;
  let NotificationMsg = userNotifications;
  const { currentUser } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const enterNotifications = () => {
    handleClose();
    navigate('/notificaciones');
  };

  const singleNotification = (NotificationMsg) => {
    handleClose();
    navigate(
      `${notificationToDoc(NotificationMsg.type)}${
        NotificationMsg.document.documentId
      }`
    );
  };

  const intervals = [
    { label: 'mes', seconds: milisecondsInMonth },
    { label: 'día', seconds: milisecondsInDay },
    { label: 'hora', seconds: milisecondsInHour },
    { label: 'minuto', seconds: milisecondsInMinute },
    { label: 'segundo', seconds: milisecondsInSecond }
  ];

  const createdTimeNotifications = (NotificationMsg) => {
    const seconds = Math.floor(Date.now() - NotificationMsg.date);
    const interval = intervals.find((i) => i.seconds < seconds);
    const count = Math.floor(seconds / interval.seconds);
    return `${count} ${interval.label}${
      count !== 1 ? (interval.label == 'mes' ? 'es' : 's') : ''
    }`;
  };

  const notificationActionMessage = (NotificationMsg) => {
    const notificationType = NotificationMsg.type;
    const textMessage = notificationConfig[notificationType].message;
    return `${textMessage} el documento`;
  };

  const actionRoute = (NotificationMsg) => {
    NotificationMsg.pathname = notificationConfig[NotificationMsg.type].path;
    singleNotification(NotificationMsg);
  };

  const readingNotification = async (NotificationMsg) => {
    await editNotificationData(currentUser.id, NotificationMsg.id, {
      read: true
    });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      classes={{ paper: classes.dialogFormat }}
    >
      {userNotifications.length !== 0 ? (
        <div>
          <Button className={classes.seeAllTag} onClick={enterNotifications}>
            <p>Ver todas</p>
          </Button>
          <div style={{ clear: 'none' }} />
          {userNotifications
            .sort((a, b) => (a.date < b.date ? 1 : -1))
            .filter((notif) => !notif.read)
            .slice(0, maxBadgeNumber)
            .map((notificationOption) => (
              <ListItem
                button
                key={notificationOption.notifier.id}
                className={classes.notificationOptions}
              >
                <Box
                  onClick={() => {
                    actionRoute(notificationOption);
                    readingNotification(notificationOption);
                  }}
                >
                  <p>
                    {notificationOption.notifier.name}{' '}
                    {notificationOption.notifier.lastName}{' '}
                    {notificationActionMessage(notificationOption)}{' '}
                    <b style={{ textDecoration: 'underline' }}>
                      {notificationOption.document.name}
                    </b>
                  </p>
                  <p className={classes.notificationTime}>
                    Hace {createdTimeNotifications(notificationOption)}
                  </p>
                </Box>
              </ListItem>
            ))}
        </div>
      ) : (
        <p className={classes.emptyNotifications}>
          No tiene notificaciones por el momento.
        </p>
      )}
    </Dialog>
  );
}

export default function ModalNotification(props) {
  const classes = useStyles();
  const { notificationTab } = props;
  const { currentUser } = useAuth();
  const [open, setOpen] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [selectedValue, setSelectedValue] = useState(Signature[0]);
  const [badgeNum, setBadgeNum] = useState(emptyBadgeNum);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });

  useEffect(() => {
    const filteredNotifications = userNotifications.filter(
      (notif) => !notif.read
    );
    setBadgeNum(filteredNotifications.length);
  }, [userNotifications]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const notifs = await getUserNotifications(currentUser.uid);
        const filteredNotifications = notifs.filter((notif) => !notif.read);
        setUserNotifications(filteredNotifications);
        setBadgeNum(filteredNotifications.length);
      } catch (error) {
        console.log(error); //THROW AN ERROR MESSAGE
      }
    };
    fetchData();
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setBadgeNum(emptyBadgeNum);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <>
      <Button
        color={notificationTab ? 'secondary' : 'primary'}
        onClick={handleClickOpen}
        className={classes.notificationButton}
      >
        <Badge
          badgeContent={badgeNum}
          max={maxBadgeNumber}
          color="error"
          className={classes.badge}
        />
        <div>
          <IconNotifications />
        </div>
        <label
          className={
            notificationTab
              ? classes.notificationLabelOn
              : classes.notificationLabel
          }
        >
          Notificaciones
        </label>
      </Button>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        className={classes.dialogBox}
        userNotifications={userNotifications}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
}
