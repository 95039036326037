import { useNavigate } from 'react-router-dom';
//MaterialUI
import useStyles from './EmptyNavBarStyles';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
//Icons
import { ReactComponent as NexumIcon } from '../../../static/icons/navBarIcons/Nexum.svg';

const EmptyNavBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const goToSignIn = () => {
    navigate('/login');
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="default">
        <Toolbar>
          <IconButton onClick={goToSignIn} className={classes.logoContainer} size="large">
            <NexumIcon className={classes.logo} />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default EmptyNavBar;
