import React, { useState } from 'react';
import CreateGroup from './CreateGroup/CreateGroup';
import CreateGroupMembers from './CreateGroupMembers/CreateGroupMembers';
import { createNewGroup } from '../../../Utilities/Firebase/GroupsData';



const NewGroup = ({
    temporalUser,
    toLogin
}) => {
    const [currentStep, setCurrentStep] = useState(1);

    const [notify, setNotify] = useState({
        isOpen: false,
        message: "",
        type: "",
    });

    const [group, setGroup] = useState({
        type: 'fisica',
        name: '',
        country: '',
        state: '',
        city: '',
        street: '',
        district: '',
        addressNum: '',
        zipCode: '',
    });

    const [documents, setDocuments] = useState({
        acta: [],
        carta: [],
        constancia: [],
        cedula: []
    });

    const [newMember, setNewMember] = useState({
        email: "",
        role: "Iniciador",
    });
    const [groupMembers, setGroupMembers] = useState([
        {
            email: temporalUser.email,
            role: "Administrador",
        },
    ]);

    // Controller Starts
    const uploadGroup = async () => {
        try {
            await createNewGroup(temporalUser.uid, group, groupMembers, documents);
        } catch (error) {
            console.log(error.message);
            return;
        };
        toLogin();
    };

    const handleDocumentChange = (file, fileType) => {
        switch (fileType) {
            case 'acta':
                setDocuments({ ...documents, acta: file });
                break;
            case 'carta':
                setDocuments({ ...documents, carta: file });
                break;
            case 'constancia':
                setDocuments({ ...documents, constancia: file });
                break;
            case 'cedula':
                setDocuments({ ...documents, cedula: file });
                break;
            default:
                break;
        };
    };

    const handleGroupTypeChange = (_, nextValue) => {
        if (nextValue !== null) {
            setGroup({ ...group, type: nextValue });
        }
    }

    const handleGroupChange = (e) => {
        e.preventDefault();
        setGroup({ ...group, [e.target.name]: e.target.value });
    };

    const handleNewMember = (e) => {
        setNewMember({ ...newMember, [e.target.name]: e.target.value });
    };

    const addNewMember = () => {
        setGroupMembers([].concat(groupMembers, newMember));
        setNewMember({
            email: "",
            role: "Iniciador",
        });
    };

    const removeMember = (index) => {
        let newListMembers = [...groupMembers];
        newListMembers.splice(index, 1);
        setGroupMembers(newListMembers);
    };

    const nextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const stepSwitch = () => {
        switch (currentStep) {
            case 1:
                return (
                    <CreateGroup
                        nextStep={nextStep}
                        handleGroupChange={handleGroupChange}
                        handleGroupTypeChange={handleGroupTypeChange}
                        group={group}
                        documents={documents}
                        handleDocumentChange={handleDocumentChange}
                        toLogin={toLogin}
                    />
                );
            case 2:
                return (
                    <CreateGroupMembers
                        handleNewMember={handleNewMember}
                        addNewMember={addNewMember}
                        removeMember={removeMember}
                        newMember={newMember}
                        groupMembers={groupMembers}
                        prevStep={prevStep}
                        createGroup={uploadGroup}
                    />
                );
            default:
                return (<h1>F</h1>);
        };
    };
    // Controller Ends

    return (
        stepSwitch()
    );
}

export default NewGroup;
