
    export const countries = [
        {
          value: 'MXN',
          label: 'México',
        },
        /* {
          value: 'USA',
          label: 'United States',
        }, */
      ];

      export const states = [
        {
          value: 'AGS',
          label: 'Aguascalientes',
        },
        {
          value: 'BC',
          label: 'Baja California',
        },
        {
          value: 'BCS',
          label: 'Baja California Sur',
        },
        {
          value: 'CAMP',
          label: 'Campeche',
        },
        {
          value: 'COAH',
          label: 'Coahuila',
        },
        {
          value: 'COL',
          label: 'Colima',
        },
        {
          value: 'CHIS',
          label: 'Chiapas',
        },
        {
          value: 'CHH',
          label: 'Chihuahua',
        },
        {
          value: 'CDMX',
          label: 'Ciudad de México',
        },
        {
          value: 'DGO',
          label: 'Durango',
        },
        {
          value: 'GTO',
          label: 'Guanajuato',
        },
        {
          value: 'GRO',
          label: 'Guerrero',
        },
        {
          value: 'HGO',
          label: 'Hidalgo',
        },
        {
          value: 'JAL',
          label: 'Jalisco',
        },
        {
          value: 'MEX',
          label: 'Estado de México',
        },
        {
          value: 'MICH',
          label: 'Michoacan',
        },
        {
          value: 'MOR',
          label: 'Morelos',
        },
        {
          value: 'NAY',
          label: 'Nayarit',
        },
        {
          value: 'NL',
          label: 'Nuevo León',
        },
        {
          value: 'OAX',
          label: 'Oaxaca',
        },
        {
          value: 'PUE',
          label: 'Puebla',
        },
        {
          value: 'QRO',
          label: 'Querétaro',
        },
        {
          value: 'QR',
          label: 'Quintana Roo',
        },
        {
          value: 'SLP',
          label: 'San Luis Potosí',
        },
        {
          value: 'SIN',
          label: 'Sinaloa',
        },
        {
          value: 'SON',
          label: 'Sonora',
        },
        {
          value: 'TAB',
          label: 'Tabasco',
        },
        {
          value: 'TAMS',
          label: 'Tamaulipas',
        },
        {
          value: 'TLAX',
          label: 'Tlaxcala',
        },
        {
          value: 'VER',
          label: 'Veracruz',
        },
        {
          value: 'YUC',
          label: 'Yucatán',
        },
        {
          value: 'ZAC',
          label: 'Zacatecas',
        },
        
      ];

      export const cities = [
        { 
          AGS: [
            { 
              value: 'AGS',
              label: 'Aguas Calientes',
            }
          ]
        },
        { 
          BC: [
            { 
              value: 'ENS',
              label: 'Ensenada',
            },
            { 
              value: 'MXLI',
              label: 'Mexicali',
            },
            {
              value: 'TJN',
              LABEL: 'Tijuana'
            }
          ]
        },
        { 
          BCS: [
            { 
              value: 'LPZ',
              label: 'La Paz',
            },
            { 
              value: 'LCB',
              label: 'Los Cabos',
            },
          ]
        },
        { 
          CAMP: [
            { 
              value: 'CAMP',
              label: 'Campeche',
            },
            { 
              value: 'CDC',
              label: 'Ciudad del Carmen',
            },
          ]
        },
        { 
          COAH: [
            { 
              value: 'SAL',
              label: 'Saltillo',
            },
            { 
              value: 'MCLV',
              label: 'Monclova-Frontera',
            },
            {
              value: 'LGN',
              label: 'La Laguna'
            },
            {
              value: 'PDN',
              label: 'Piedras Negras'
            }
          ]
        },
        { 
          COL: [
            { 
              value: 'TEC',
              label: 'Tecomán',
            },
            { 
              value: 'CVDA',
              label: 'Colima-Vila de Álvarez',
            },
            {
              value: 'MANZ',
              label: 'Manzanillo'
            },
          ]
        },
        { 
          CHIS: [
            { 
              value: 'TGTZ',
              label: 'Tuxtla Gutiérrez',
            },
            { 
              value: 'TCHL',
              label: 'Tapachula',
            },
          ]
        },
        { 
          CHH: [
            { 
              value: 'CUU',
              label: 'Chihuahua',
            },
            { 
              value: 'JRZ',
              label: 'Juarez',
            },
            {
              value: 'CAM',
              label: 'Camargo'
            },
            {
              value: 'DEL',
              label: 'Delicias'
            }
          ]
        },
        { 
          CDMX: [
            { 
              value: 'CDMX',
              label: 'Ciudad de México',
            }
          ]
        },
        { 
          DGO: [
            { 
              value: 'DGO',
              label: 'Durango',
            },
          ]
        },
        { 
          GTO: [
            { 
              value: 'CEL',
              label: 'Celaya',
            },
            { 
              value: 'GTO',
              label: 'Guanajuato',
            },
            {
              value: 'IRA',
              label: 'Irapuato'
            },
            {
              value: 'LN',
              label: 'León'
            },
            {
              value: 'LPP',
              label: 'La Piedad-Pénjamo'
            },
            {
              value: 'SFDR',
              label: 'San Francisco del Rincón'
            },
            {
              value: 'SAL',
              label: 'Salamanca'
            }
          ]
        },
        { 
          GRO: [
            { 
              value: 'APLC',
              label: 'Acapulco',
            },
            { 
              value: 'CHIL',
              label: 'Chilpancingo',
            },
          ]
        },
        { 
          HGO: [
            { 
              value: 'TUL',
              label: 'Tula',
            },
            { 
              value: 'TLNC',
              label: 'Tulancingo',
            },
            {
              value: 'PACH',
              label: 'Pachuca'
            }
          ]
        },
        { 
          JAL: [
            { 
              value: 'GDL',
              label: 'Guadalajara',
            },
            { 
              value: 'OCO',
              label: 'Ocotlán',
            },
            {
              value: 'PVLL',
              label: 'Puerto Vallarta'
            }
          ]
        },
        { 
          MEX: [
            { 
              value: 'TOL',
              label: 'Toluca',
            },
          ]
        },
        { 
          MICH: [
            { 
              value: 'GDL',
              label: 'Guadalajara',
            },
            { 
              value: 'OCO',
              label: 'Ocotlán',
            },
            {
              value: 'PVLL',
              label: 'Puerto Vallarta'
            }
          ]
        },
        { 
          MOR: [
            { 
              value: 'CAU',
              label: 'Cautla',
            },
            { 
              value: 'CUER',
              label: 'Cuernavaca',
            }
          ]
        },
        { 
          NAY: [
            { 
              value: 'TEP',
              label: 'Tepic',
            }
          ]
        },
        { 
          NL: [
            { 
              value: 'MTY',
              label: 'Monterrey',
            }
          ]
        },
        { 
          OAX: [
            { 
              value: 'OAX',
              label: 'Oaxaca',
            },
            { 
              value: 'TEHS',
              label: 'Tehuantepec-Salina Cruz',
            }
          ]
        },
        { 
          PUE: [
            { 
              value: 'PUET',
              label: 'Puebla-Tlaxcala',
            },
            { 
              value: 'TEH',
              label: 'Tehuacán',
            }
          ]
        },
        { 
          QRO: [
            { 
              value: 'QRO',
              label: 'Querétaro',
            },
            { 
              value: 'SJDR',
              label: 'San Juan del Río',
            }
          ]
        },
        { 
          QR: [
            { 
              value: 'CAN',
              label: 'Cancún',
            },
            { 
              value: 'CHE',
              label: 'Chetumal',
            }
          ]
        },
        { 
          SLP: [
            { 
              value: 'RCF',
              label: 'Rioverde-Ciudad Fernández',
            },
            { 
              value: 'SLP',
              label: 'San Luis Potosí-Soledad',
            }
          ]
        },
        { 
          SIN: [
            { 
              value: 'MOCH',
              label: 'Los Mochis',
            },
            { 
              value: 'CUL',
              label: 'Culiacán',
            },
            {
              value: 'MAZ',
              label: 'Mazatlán'
            }
          ]
        },
        { 
          SON: [
            { 
              value: 'OBR',
              label: 'Ciudad Obregón',
            },
            { 
              value: 'GUAY',
              label: 'Guaymas',
            },
            {
              value: 'HER',
              label: 'Hermosillo'
            }
          ]
        },
        { 
          TAB: [
            { 
              value: 'CARD',
              label: 'Cárdenas',
            },
            { 
              value: 'VILLH',
              label: 'Villahermosa',
            }
          ]
        },
        { 
          TAMS: [
            { 
              value: 'TAMP',
              label: 'Tampico-Pánuco',
            },
            { 
              value: 'MTMRS',
              label: 'Matamoros',
            },
            {
              value: 'NUL',
              label: 'Nuevo Laredo'
            },
            {
              value: 'RRB',
              label: 'Reynosa-Río Bravo'
            },
            {
              value: 'CVIC',
              label: 'Ciudad Victoria'
            },
          ]
        },
        { 
          TLAX: [
            { 
              value: 'TLAX',
              label: 'Tlaxcala-Apizaco',
            }
          ]
        },
        { 
          VER: [
            { 
              value: 'VER',
              label: 'Veracruz',
            },
            { 
              value: 'COR',
              label: 'Córdoba',
            },
            {
              value: 'ORI',
              label: 'Orizaba'
            },
            {
              value: 'XAL',
              label: 'Xalapa'
            },
            {
              value: 'POZ',
              label: 'Poza Rica'
            },
            {
              value: 'COAT',
              label: 'Coatzacoalcos'
            },
            {
              value: 'MNA',
              label: 'Minatitlán'
            }
          ]
        },
        { 
          YUC: [
            { 
              value: 'MER',
              label: 'Mérida',
            }
          ]
        },
        { 
          ZAC: [
            { 
              value: 'ZAC',
              label: 'Zacatecas-Guadalupe',
            }
          ]
        },
      ];
