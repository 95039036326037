import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    fontSize: '14px'
  },
  notificationButton: {
    padding: '14px 15px 0px 15px',
    display: 'grid',
    [theme.breakpoints.only('xs')]: {
      padding: '12px 15px 0px 15px'
    }
  },
  notificationLabel: {
    cursor: 'Pointer',
    fontSize: '10px',
    marginTop: '-3px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0px'
    },
    lineHeight: '1.5',
    color: 'rgba(0, 0, 0, 0.87)'
  },
  notificationLabelOn: {
    cursor: 'Pointer',
    fontSize: '10px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '0px'
    },
    lineHeight: '1.5',
    color: 'primary'
  },
  badge: {
    position: 'relative ',
    fontSize: '10px',
    left: '-23px',
    top: '3px',
    [theme.breakpoints.only('xs')]: {
      left: '-3px'
    }
  },

  //Notification Dialog
  seeAllTag: {
    float: 'right',
    paddingRight: '20px',
    textDecoration: 'underline',
    fontSize: '14px',
    cursor: 'pointer',
    height: '30px'
  },
  dialogFormat: {
    paddingTop: '10px',
    position: 'fixed',
    top: '50px',
    right: '-15px',
    fontSize: '14px',
    fontFamily: 'roboto',
    [theme.breakpoints.only('xs')]: {
      top: '40px'
    }
  },
  notificationTime: {
    fontStyle: 'italic',
    lineHeight: '1.67px',
    marginLeft: '5px',
    fontSize: '12px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px'
    }
  },
  emptyNotifications: {
    fontWeight: 'bold',
    fontSize: '14px',
    margin: '17px'
  }
}));

export default useStyles;
