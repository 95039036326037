import { Avatar, Button } from '@mui/material';
import { useAuth } from '../../../../Contexts/AuthContext';

import useStyles from './ModalUserMenuStyles';

import avatarPlaceholder from '../../../../static/icons/navBarIcons/Icon_Avatar.svg';
import avatarSelectedPlaceholder from '../../../../static/icons/navBarIcons/Icon_Avatar_Selected.svg';

function ModalUserMenu({ handleClick, userTab }) {
  const { currentUser } = useAuth();
  const classes = useStyles();

  return (
    <>
      <Button onClick={handleClick} className={classes.labelBlock}>
        <Avatar
          alt={`${currentUser.name} ${currentUser.lastName}`}
          src={userTab ? avatarSelectedPlaceholder : avatarPlaceholder}
          className={classes.avatar}
        >
          {/* {`${name.charAt(0)}${lastName.charAt(0)}`} */}
        </Avatar>
        <p
          className={userTab ? classes.textOn : classes.text}
          style={{ display: 'block' }}
        >
          {currentUser.name}
        </p>
      </Button>
    </>
  );
}

export default ModalUserMenu;
