import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  spacer: {
    minWidth: '20px'
  },
  tab: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    '& .MuiGrid-container': {
      gap: '10px'
    }
  },
  menu: {
    position: 'fixed',
    top: '50px',
    right: '-15px',
    fontSize: '14px',
    fontFamily: 'roboto',
    [theme.breakpoints.only('xs')]: {
      top: '40px'
    }
  },
  mobileMenu: {
    '& .MuiSvgIcon-root': {
      minWidth: '29px'
    }
  },
  text: {
    margin: '0 0 3px 0',
    fontFamily: 'Roboto',
    fontSize: '10px'
  },
  textOn: {
    margin: '0 0 3px 0',
    fontFamily: 'Roboto',
    fontSize: '10px',
    color: ' #f77c2e'
  },
  avatarOutline: {
    height: '25px',
    width: '25px',
    border: '2px solid #f77c2e'
  },
  avatarOutlineOff: {
    height: '25px',
    width: '25px',
    border: '2px solid #ffffff'
  },
  logo: {
    display: 'flex'
  },
  logoContainer: {
    backgroundColor: 'transparent !important'
  },
  list: {
    width: 250
  },
  tabText: {
    fontFamily: 'Roboto',
    fontSize: '14px'
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  },
  icon: {
    height: 20,
    [theme.breakpoints.down('lg')]: {
      height: 20
    },
    [theme.breakpoints.only('xs')]: {
      height: 16
    }
  }
}));

export default useStyles;
