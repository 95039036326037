import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: '100vh',
        backgroundColor: 'white',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: '#041854'
        }
    },
    img: {
        width: "100%",
        height: "100%",
        objectFit: "cover"
    },
    nexumLogo: {
        position: "absolute",
        bottom: "2%",
        left: "2%",
    },
    overlay: {
        flex: 1, 
        backgroundColor: '#041854'
    },
    overlayMobile: {
        flex: 1,
        backgroundColor: 'white',
        [theme.breakpoints.only('xs')]: {
            backgroundColor: '#041854'
        }
    }
}));

export default useStyles
