import { db } from "../Firebase";

const collectionName = "GroupUserData";
const ref = db.collection(collectionName);

export const addMembersToGroup = (_groupId, members) => {
    
    members.forEach(member => {
        ref.add({
            GroupId: _groupId,
            Email: member.email,
            Role: member.role
        })
        .then(_ => {
        })
        .catch(error => {
            console.log("Error while creating group-user document ", error);
        })
    });
};