import React from 'react'
import {Snackbar} from '@mui/material'
import { Alert } from '@mui/material';

const Notification = ({ notify = {isOpen: false, type: 'success', message: ''}, setNotify = () => {}}) =>{

    const handleClose = (event, reason) => {

        if (reason === 'clickaway'){
            return;
        }
        setNotify({
            ...notify,
            isOpen: false,
        })
    }

    if(!['success', 'error', 'info', 'warning'].includes(notify.type)){
        notify.type = 'success'
    }

    return (
        <Snackbar
            style = {{marginTop: '3rem', marginLeft: '-0.5rem'}}
            open = {notify.isOpen}
            autoHideDuration = {2500}
            anchorOrigin = {{vertical: 'top', horizontal: 'left'}}
            onClose = {handleClose}
        >
            <Alert
                severity = {notify.type}
            >
                {notify.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification
