import { db } from '../Firebase';
import firebase from '../Firebase';
import { collection, query, where, getDocs } from 'firebase/compat/firestore';

const dbRef = db.collection('UserData');

export const saveUserNotification = async (userEmail, notification) => {
  const findUserByEmail = db
    .collection('UserData')
    .where('mail', '==', userEmail)
    .get()
    .then((querySnapshot) => {
      querySnapshot.forEach((user) => {
        // user.data() is never undefined for query user snapshots

        const userNotifRef = dbRef.doc(user.id).collection('Notification');
        const savedNotification = userNotifRef.add(notification);

        return savedNotification;
      });
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
    });
};

export const getUserNotifications = async (userId) => {
  const notRef = dbRef.doc(userId).collection('Notification');
  let notifications = [];
  const snapshot = await notRef.get();
  snapshot.forEach((notification) => {
    const tempNot = notificationObject(notification.data());
    tempNot.id = notification.id;
    notifications.push(tempNot);
  });
  return notifications;
};

export const getNotificationsByDocument = async (userId, docId) => {
  const notifRef = dbRef
    .doc(userId)
    .collection('Notification')
    .where('document.documentId', '==', docId);
  let notification = [];
  const snapshot = await notifRef.get();
  snapshot.forEach((notifications) => {
    let tempNot = notificationObject(notifications.data());
    tempNot.id = notifications.id;
    notification.push(tempNot);
  });
  return notification;
};

export const editNotificationData = async (userId, notifId, newData) => {
  try {
    const notification = await dbRef
      .doc(userId)
      .collection('Notification')
      .doc(notifId);
    return notification.update(newData);
  } catch (error) {
    console.error('Error updating document: ', error);
  }
};

const notificationObject = (notification) => {
  const tempNotification = {
    date: notification.date,
    type: notification.type,
    read: notification.read,
    document: {
      name: notification.document.name,
      documentId: notification.document.documentId
    },
    notifier: {
      id: notification.notifier.id,
      name: notification.notifier.name,
      lastName: notification.notifier.lastName
    }
  };
  return tempNotification;
};
