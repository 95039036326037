import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import useStyles from './LoginStyles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../Contexts/AuthContext';
import { ReactComponent as LoginNexumIcon } from '../../../static/icons/authIcons/Vector.svg';
//show password
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

const Login = (props) => {
  const [data, setData] = useState({
    password: '',
    mail: ''
  });
  const [visibility, setVisibility] = useState(false);
  const handleClickShowPass = () => {
    setVisibility(!visibility);
  };

  const classes = useStyles();

  const navigate = useNavigate();

  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSuccessfulAuth = () => {
    navigate('/');
  };

  const [loading, setLoading] = useState(false);

  const { login } = useAuth();

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(data);
      handleSuccessfulAuth();
    } catch {}
    setLoading(false);
  };

  const renderRegister = () => {
    props.goToRegister();
  };

  const renderForgottenPassword = () => {
    props.goToForgottenPassword();
  };

  return (
    <Grid container justifyContent="center">
      <Grid
        className={classes.colorChange}
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={8}
        sm={6}
      >
        <Grid
          item
          justifyContent="center"
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <LoginNexumIcon className={classes.icon} />
        </Grid>
        <form className={classes.form}>
          <Grid item justifyContent="center">
            <h2 className={classes.titleText}>Iniciar sesión</h2>
          </Grid>
          <Grid container item direction="column" alignItems="flex-start">
            <p>Correo electrónico</p>
          </Grid>
          <TextField
            className={classes.dataField}
            InputProps={{
              className: classes.dataField,
              inputProps: {
                style: {
                  height: 30,
                  padding: '0 12px'
                }
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
            required
            id="correo"
            name="mail"
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <Grid container item direction="column" alignItems="flex-start">
            <p>Contraseña</p>
          </Grid>
          <TextField
            className={classes.dataField}
            required
            InputProps={{
              className: classes.dataField,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPass}
                    edge="end"
                    size="large"
                  >
                    {visibility ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: {
                style: {
                  height: 30,
                  padding: '0 12px'
                }
              }
            }}
            InputLabelProps={{
              shrink: true
            }}
            id="password"
            name="password"
            onChange={handleChange}
            variant="outlined"
            type={visibility ? 'text' : 'password'}
            fullWidth
          />
          <Grid
            container
            item
            justifyContent="center"
            spacing={1}
            className={classes.buttons}
          >
            <Grid container item direction="row" justifyContent="flex-end">
              <b
                className={classes.colorChange2}
                onClick={renderForgottenPassword}
              >
                ¿Olvidaste tu contraseña?
              </b>
            </Grid>
            <Grid item xs={12}>
              <Button
                className={classes.buttonWhite}
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
                onClick={onLoginSubmit}
              >
                Entrar
              </Button>
            </Grid>
            <Grid item>
              <p>
                No tengo cuenta
                <b className={classes.colorChange2} onClick={renderRegister}>
                  Registrarme
                </b>
              </p>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Login;
