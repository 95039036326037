import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import useStyles, { OutlinedButton, WBTextField } from './VerifyCodeStyles';
import Notification from '../../../Helpers/Notification';
import { useAuth } from '../../../Contexts/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';

const VerifyCode = (props) => {
  const {
    data,
    nextStep,
    prevStep,
    waitLoad,
    goToDashboard,
    verification,
    handleVerificationChange,
    temporalUserId
  } = props;

  const { signUp } = useAuth();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [loading, setLoading] = useState(false);

  const sendCode = async () => {
    setLoading(true);
    const code2 = verification.code;
    const resultCode = verification.verificationId;
    const phoneCredential = firebase.auth.PhoneAuthProvider.credential(
      resultCode,
      code2
    );
    let flag = false;

    await firebase
      .auth()
      .signInWithCredential(phoneCredential)
      .then(() => {
        return firebase.auth().currentUser.delete();
      })
      .then(() => {
        return signUp(data);
      })
      .then(() => {
        flag = true;
      })
      .catch((error) => {
        let errorMessage = '';
        switch (error.code) {
          case 'auth/email-already-in-use':
            errorMessage =
              'El correo electrónico ingresado ya esta siendo utilizado por otra cuenta';
            break;
          case 'auth/missing-verification-code':
            errorMessage =
              'El campo código de verificación no puede estar vacío';
            break;
          default:
            errorMessage = 'Código de verificación no válido';
            break;
        }
        setNotify({
          isOpen: true,
          message: errorMessage,
          type: 'error'
        });
      });

    if (flag) {
      goToDashboard();
    }

    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  const classes = useStyles();
  return (
    <Grid container justifyContent="center">
      <Grid
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={8}
        className={classes.textColor}
      >
        <Grid item justifyContent="center">
          <Typography
            variant="h5"
            className={classes.textColor}
            style={{ fontWeight: 'bold', marginBottom: '2rem' }}
          >
            {'Verifica tu cuenta'}
          </Typography>
        </Grid>
        <Grid container item direction="column" alignItems="flex-start">
          <Typography className={classes.text}>
            {'Ingresa tu código de verificación'}
          </Typography>
        </Grid>
        <WBTextField
          required
          id="code-textfield"
          name="code"
          placeholder="XXXXXX"
          variant="outlined"
          InputProps={{
            className: classes.txtField
          }}
          InputLabelProps={{
            className: classes.textColor
          }}
          onChange={(event) => handleVerificationChange(event)}
          fullWidth
        />
        <Grid
          container
          item
          justifyContent="center"
          spacing={1}
          className={classes.buttons}
        >
          <Grid item>
            <OutlinedButton
              variant="outlined"
              color="primary"
              id="resend-code"
              className={classes.buttonResend}
              onClick={prevStep}
              disabled={waitLoad}
            >
              {waitLoad && (
                <CircularProgress
                  style={{ marginRight: 5 }}
                  invisible={waitLoad}
                  size={14}
                  color="inherit"
                />
              )}
              {'Reenviar código'}
            </OutlinedButton>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={loading}
              className={classes.buttonWhite}
              onClick={sendCode}
            >
              {'Verificar'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default VerifyCode;
