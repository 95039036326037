export const maxLengthNameDocument = 100;
export const maxLengthNameDocumentToShow = 20;
export const maxLengthName = 35;
export const maxLengthFolio = 20;
export const maxLengthComments = 500;
export const maxDate = "2100-01-01";
export const minDate = "1900-01-01";
export const maxLengthAddress = 55;
export const maxLengthPostalCode = 10;
export const maxLengthRFC = 13;
export const maxLengthPhone = 10;
