export const notificationConfig = {
  'document created': {
    message: 'creó',
    path: '/edita_documento'
  },
  'document edited': {
    message: 'editó',
    path: '/edita_documento'
  },
  'signers edited': {
    message: 'editó los firmates d',
    path: '/edita_documento'
  },
  'document approved': {
    message: 'aprobó',
    path: '/edita_documento'
  },
  'added as a signer': {
    message: 'agregó',
    path: '/edita_documento'
  },
  'document published': {
    message: 'publicó',
    path: './firma_documento'
  },
  'document signed': {
    message: 'firmó',
    path: './firma_documento'
  },
  'document deleted': {
    message: 'elimino',
    path: '/edita_documento'
  }
};

export const notificationToDoc = (notifType) => {
  switch (notifType) {
    case 'document created':
    case 'document edited':
    case 'signers edited':
    case 'document approved':
    case 'added as a signer':
      return '/edit/document/';
    case 'document published':
    case 'document signed':
      return '/sign/document/';

    default:
      return '';
  }
};
