import * as React from "react";
import { SvgIcon } from '@mui/material'

function SvgIconEditar(props) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <switch>
          <g>
            <path d="M21.1 5.8L15.5.2c-.2-.1-.4-.2-.5-.2H5.1C3.9 0 3 .9 3 2.1v19.8c0 1.2.9 2.1 2.1 2.1h14.1c1.2 0 2.1-.9 2.1-2.1V6.3c0-.2-.1-.3-.2-.5zm-5.4-3.4l3.2 3.2h-2.5c-.4 0-.7-.3-.7-.7V2.4zm3.5 20.2H5.1c-.4 0-.7-.3-.7-.7V2.1c0-.4.3-.7.7-.7h9.1v3.5c0 1.2.9 2.1 2.1 2.1h3.5v14.9c.1.4-.2.7-.6.7z" />
            <path d="M17.4 9.7c-.8-.8-2.2-.8-3 0l-1 1-1.1 1.1-1.4 1.4-2.1 2.1-1 1-.1.1c0 .1-.1.1-.1.2l-1 3c-.1.3 0 .5.2.7.2.2.5.3.7.2l3-1c.1 0 .2-.1.3-.2l2.9-2.9 3.7-3.7c.8-.8.8-2.2 0-3zm-7.5 8.4l-1.5.5.5-1.5.4-.4 1 1-.4.4zm1.4-1.3l-1-1 3.4-3.4 1 1-3.4 3.4zm5.1-5.1l-.7.7-1-1 .7-.7c.3-.3.7-.3 1 0 .2.3.2.7 0 1z" />
          </g>
        </switch>
      </svg>
    </SvgIcon>

  );
}

export default SvgIconEditar;
