import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import useStyles from './AuthStyles';
import nexumLogo from '../../static/images/LogoNexumBlanco.png';
import VerifyAccount from './VerifyAccount/VerifyAccount';
import VerifyCode from './VerifyCode/VerifyCode';
// import CreateGroup from './NewGroup/CreateGroup/CreateGroup';
import Login from './Login/Login';
import Register from './Register/Register';
import NewGroup from './NewGroup/NewGroup';
import ForgottenPassword from './ForgottenPassword/ForgottenPassword';
import UseWindowDimensions from '../../customHooks/UseWindowDimensions';
import { useAuth } from '../../Contexts/AuthContext';

const Auth = () => {
  const { width, theme } = UseWindowDimensions();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    name: '',
    lastName: '',
    bday: '',
    rfc: '',
    address: '',
    suburb: '',
    company: '',
    country: '',
    city: '',
    state: '',
    password: '',
    confirmPass: '',
    mail: '',
    phone: '',
    cp: ''
  });

  const [verification, setVerification] = useState({
    code: '',
    verificationId: null
  });
  const [temporalUser, setTemporalUser] = useState(currentUser);

  const [waitLoad, setWaitLoad] = useState(false);

  // Controller Logic Starts

  const handleUserChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const handleVerificationChange = (e) => {
    setVerification({ ...verification, [e.target.name]: e.target.value });
  };
  const addVerifier = (verifier) => {
    setVerification({ ...verification, verificationId: verifier });
  };

  const nextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
    setWaitLoad(false);
  };

  const goToRegister = () => {
    setCurrentStep(2);
  };

  const goToLogin = () => {
    setCurrentStep(1);
  };

  const goToForgottenPassword = () => {
    setCurrentStep(6);
  };

  const goToDashboard = () => {
    navigate('/');
  };
  // Controller Logic Ends

  // View Starts
  const classes = useStyles();

  const stepSwitch = () => {
    switch (currentStep) {
      case 1:
        return (
          <Login
            goToRegister={goToRegister}
            goToForgottenPassword={goToForgottenPassword}
          />
        );
      case 2:
        return (
          <Register
            data={data}
            goToLogin={goToLogin}
            nextStep={nextStep}
            prevStep={prevStep}
            handleChange={handleUserChange}
            handleVerificationChange={handleVerificationChange}
          />
        );
      case 3:
        return (
          <VerifyAccount
            nextStep={nextStep}
            prevStep={prevStep}
            setWaitLoad={setWaitLoad}
            data={data}
            verification={verification}
            handleUserChange={handleUserChange}
            handleVerificationChange={handleVerificationChange}
            addVerifier={addVerifier}
            setTemporalUser={setTemporalUser}
          />
        );
      case 4:
        return (
          <VerifyCode
            data={data}
            waitLoad={waitLoad}
            verification={verification}
            handleVerificationChange={handleVerificationChange}
            nextStep={nextStep}
            prevStep={prevStep}
            goToDashboard={goToDashboard}
          />
        );
      case 5:
        return <NewGroup toLogin={goToLogin} temporalUser={temporalUser} />;
      case 6:
        return <ForgottenPassword goToLogin={goToLogin} />;
      default:
        return <h1>F</h1>;
    }
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={12} sm={6} className={classes.overlay}>
        {width >= theme.breakpoints.values.sm ? (
          <img src={nexumLogo} className={classes.nexumLogo} />
        ) : null}
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6}>
        <Grid item xs={12}>
          {stepSwitch()}
        </Grid>
        <Grid item container className={classes.overlayMobile} justifyContent="center">
          <Grid item>
            {width < theme.breakpoints.values.sm ? (
              <img src={nexumLogo} />
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Auth;
