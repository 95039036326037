import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  buttons: {
    marginTop: '7px'
  },
  form: {
    minWidth: '100%'
  },

  underlineB: {
    color: 'black',
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#f77c2e'
    },
    marginTop: '3.5rem',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0
    }
  },

  buttonWhite: {
    marginTop: '2rem',
    color: 'white'
  },

  dataField: {
    height: 30,
    backgroundColor: 'white',
    borderRadius: 6
  },

  icon: {
    display: 'flex',
    width: '6.6rem',
    height: '6.6rem',
    marginLeft: '1rem'
  },

  primaryText: {
    color: '#041854'
  },

  titleText: {
    fontSize: '30px',
    color: '#041854',
    [theme.breakpoints.only('xs')]: {
      color: 'white'
    }
  },

  colorChange: {
    color: '#041854',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: '#041854',
      color: 'white'
    }
  },

  colorChange2: {
    marginLeft: '0.3rem',
    color: '#041854',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: '#041854',
      color: 'white'
    },
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: '#f77c2e'
    }
  }
}));

export default useStyles;
