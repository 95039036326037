import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import useStyles from './ForgottenPasswordStyles';
import { useAuth } from '../../../Contexts/AuthContext';
import { ReactComponent as LoginNexumIcon } from '../../../static/icons/authIcons/Vector.svg';
import Notification from '../../../Helpers/Notification';

const ForgottenPassword = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({
    mail: ''
  });
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const { forgottenPassword } = useAuth();
  const handleChange = (e) => {
    e.preventDefault();
    setData({ ...data, [e.target.name]: e.target.value });
  };
  const onForgottenPassword = async (e) => {
    e.preventDefault();
    if (data.mail == '') {
      setNotify({
        isOpen: true,
        message: 'Campo de correo electrónico no puede estar vacío',
        type: 'error'
      });
    } else {
      try {
        setLoading(true);
        await forgottenPassword(data.mail);
        props.goToLogin();
      } catch {}
      setLoading(false);
    }
  };
  const renderLogin = () => {
    props.goToLogin();
  };
  return (
    <Grid container justifyContent="center">
      <Grid
        className={classes.colorChange}
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        xs={8}
        sm={6}
      >
        <Grid
          item
          justifyContent="center"
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <LoginNexumIcon className={classes.icon} />
        </Grid>
        <form className={classes.form}>
          <Grid item justifyContent="center">
            <h2 className={classes.titleText}>Restablece tu contraseña</h2>
          </Grid>
          <Grid container direction="column" alignItems="flex-start">
            <p>
              Te enviaremos un correo eletrónico con más instrucciones sobre
              como restablecer tu contraseña
            </p>
          </Grid>
          <Grid container item direction="column" alignItems="flex-start">
            <p>Correo electrónico</p>
          </Grid>
          <TextField
            className={classes.dataField}
            InputProps={{
              className: classes.dataField
            }}
            InputLabelProps={{
              shrink: true
            }}
            required
            id="correo"
            name="mail"
            onChange={handleChange}
            variant="outlined"
            fullWidth
          />
          <Grid
            container
            item
            justifyContent="center"
            spacing={1}
            className={classes.buttons}
          >
            <Grid item xs={12} style={{ marginTop: '-5rem' }}>
              <Button
                className={classes.buttonWhite}
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
                onClick={onForgottenPassword}
              >
                Enviar
              </Button>
            </Grid>
            <Grid item>
              <p>
                <b className={classes.colorChange2} onClick={renderLogin}>
                  Iniciar sesión
                </b>
              </p>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default ForgottenPassword;
