import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../customHooks/UseWindowDimensions';
import { useNavigate, useLocation } from 'react-router-dom';
//MaterialUI
import useStyles from './NavBarStyles';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
//Icons
import AddIcon from '@mui/icons-material/Add';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CreateIcon from '@mui/icons-material/Create';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  IconInicio,
  IconCrearDocumento,
  IconEditar,
  IconFirmar
} from 'static/icons/navBarIcons';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import { ReactComponent as NexumIcon } from '../../../static/icons/navBarIcons/Nexum.svg';
//Static files
import avatarPlaceholder from '../../../static/icons/navBarIcons/Icon_Avatar.svg';
import avatarSelectedPlaceholder from '../../../static/icons/navBarIcons/Icon_Avatar_Selected.svg';
import { useAuth } from '../../../Contexts/AuthContext';
import { db } from '../../../Utilities/Firebase';
import { Fragment } from 'react';
import { Settings } from '@mui/icons-material';
import ModalNotification from './ModalNotification/ModalNotification';
import ModalUserMenu from './ModalUserMenu/ModalUserMenu';
import { getUserNotifications } from 'Utilities/Firebase/NotificationData';

const NavBar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const { logout, currentUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [drawer, setDrawer] = useState(false);
  const [userTab, setUserTab] = useState(false);
  const [adminMode, setAdminMode] = useState(false);
  const [notificationTab, setNotificationTab] = useState(false);
  const [userData, setUserData] = useState({
    name: ''
  });

  const [navOptions, setNavOptions] = useState({
    options: ['Perfil', 'Configuración', 'Cerrar sesión'],
    drawerOptions: [
      `Inicio`,
      'Crear Documento',
      'Editar',
      'Firmar',
      'Notificaciones'
    ],
    drawerIcons: [
      <IconInicio className={classes.icon} />,
      <IconCrearDocumento className={classes.icon} />,
      <IconEditar className={classes.icon} />,
      <CreateIcon />,
      <ModalNotification />
    ]
  });

  useEffect(() => {
    //Get the user data used throught the different components at start
    db.collection('UserData')
      .doc(currentUser.uid)
      .get()
      .then((doc) => {
        setUserData({
          name: doc.data().Name
        });
      })
      .catch((error) =>
        console.log(
          `No se pudo establecer una conexión con el servidor ${error}`
        )
      );
  }, [currentUser.uid]);

  useEffect(() => {
    // Check if the current user is an Administrator
    if (currentUser.uid === process.env.REACT_APP_ADMIN_ID) {
      setAdminMode(true);
      setNavOptions({
        options: ['Perfil', 'Configuración', 'Cerrar sesión'],
        drawerOptions: [
          'Inicio',
          'Crear Documento',
          'Editar',
          'Firmar',
          'Cuentas',
          'Solicitudes',
          'Cargar Documento'
          // 'Prueba PDF'
        ],
        drawerIcons: [
          <IconInicio className={classes.icon} />,
          <IconCrearDocumento className={classes.icon} />,
          <IconEditar className={classes.icon} />,
          <IconFirmar className={classes.icon} />,
          <PersonOutlineIcon />,
          <AddIcon />,
          <IconCrearDocumento className={classes.icon} />
          // <IconFirmar className={classes.icon} />
        ]
      });
    } else if (currentUser.uid === process.env.REACT_APP_SERVICE_ADMIN_ID) {
      setAdminMode(true);
      setNavOptions({
        options: ['Perfil', 'Configuración', 'Cerrar sesión'],
        drawerOptions: ['Inicio', 'Crear Documento', 'Editar', 'Firmar', 'Log'],
        drawerIcons: [
          <IconInicio className={classes.icon} />,
          <IconCrearDocumento className={classes.icon} />,
          <IconEditar className={classes.icon} />,
          <IconFirmar className={classes.icon} />,
          <AddIcon />
        ]
      });
    } else {
      setAdminMode(false);
      setNavOptions({
        options: ['Perfil', 'Configuración', 'Cerrar sesión'],
        drawerOptions: ['Inicio', 'Crear Documento', 'Editar', 'Firmar'],
        drawerIcons: [
          <IconInicio className={classes.icon} />,
          <IconCrearDocumento className={classes.icon} />,
          <IconEditar className={classes.icon} />,
          <IconFirmar className={classes.icon} />
        ]
      });
    }
  }, [currentUser.uid]);

  useEffect(() => {
    if (location.pathname == '/') {
      setTabValue(0);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname == '/crea_documento') {
      setTabValue(1);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname == '/edita_documento') {
      setTabValue(2);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname == '/firma_documento') {
      setTabValue(3);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname == '/configuración_cuenta') {
      setTabValue(5);
      setUserTab(true);
      setNotificationTab(false);
    }
    if (location.pathname == '/perfil') {
      setTabValue(5);
      setUserTab(true);
      setNotificationTab(false);
    }
    if (location.pathname === '/admin/accounts') {
      setTabValue(4);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname === '/admin/requests') {
      setTabValue(5);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname === '/logs') {
      setTabValue(4);
      setUserTab(false);
      setNotificationTab(false);
    }
    if (location.pathname === '/notificaciones') {
      setTabValue(6);
      setUserTab(false);
      setNotificationTab(true);
    }
  }, [location]);

  const enterProfile = () => {
    handleMobileMenuClose();
    navigate('/perfil');
  };

  const enterConfig = () => {
    handleMobileMenuClose();
    navigate('/configuración_cuenta');
  };

  const lastName = 'last name';

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const renderDashboard = () => {
    setTabValue(0);
    navigate('/');
  };

  const renderAccounts = () => {
    setTabValue(4);
    navigate('/admin/accounts');
  };

  const renderRequests = () => {
    setTabValue(5);
    navigate('/admin/requests');
  };

  const renderLoadDocument = () => {
    setTabValue(6);
    navigate('/admin/load_document');
  };

  // const renderPDFTrial = () => {
  //   setTabValue(7);
  //   navigate('/admin/pdf_trial');
  // };

  const renderLogs = () => {
    setTabValue(4);
    navigate('/logs');
  };

  const renderCreateDoc = () => {
    setTabValue(1);
    navigate('/crea_documento');
  };

  const renderEdit = () => {
    setTabValue(2);
    navigate('/edita_documento');
  };

  const renderSign = () => {
    setTabValue(3);
    navigate('/firma_documento');
  };

  const logoutHandler = async () => {
    handleMobileMenuClose();
    try {
      await logout();
      navigate('/');
    } catch {
      alert('Hubo un error al momento de hacer logout');
    }
  };

  //Drawer Logic Starts
  const drawerButton = () => {
    if (width <= theme.breakpoints.values.sm) {
      return (
        <IconButton edge="start" onClick={toggleDrawer(true)} size="large">
          <MenuIcon />
        </IconButton>
      );
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const drawerKeys = () => {
    return navOptions.drawerOptions.map((text) => {
      return text.replace(/\s+/g, '');
    });
  };

  const userDrawerList = (
    <Fragment>
      <ListItem onClick={renderDashboard} button key={drawerKeys()[0]}>
        <ListItemIcon>{navOptions.drawerIcons[0]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[0]} />
      </ListItem>
      <ListItem onClick={renderCreateDoc} button key={drawerKeys()[1]}>
        <ListItemIcon>{navOptions.drawerIcons[1]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[1]} />
      </ListItem>
      <ListItem onClick={renderEdit} button key={drawerKeys()[2]}>
        <ListItemIcon>{navOptions.drawerIcons[2]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[2]} />
      </ListItem>
      <ListItem onClick={renderSign} button key={drawerKeys()[3]}>
        <ListItemIcon>{navOptions.drawerIcons[3]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[3]} />
      </ListItem>
      <ListItem onClick={renderSign} button key={drawerKeys()[4]}>
        <ListItemIcon>{navOptions.drawerIcons[4]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[4]} />
      </ListItem>
    </Fragment>
  );

  const adminDrawerList = (
    <Fragment>
      <ListItem onClick={renderDashboard} button key={drawerKeys()[0]}>
        <ListItemIcon>{navOptions.drawerIcons[0]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[0]} />
      </ListItem>
      <ListItem onClick={renderCreateDoc} button key={drawerKeys()[1]}>
        <ListItemIcon>{navOptions.drawerIcons[1]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[1]} />
      </ListItem>
      <ListItem onClick={renderEdit} button key={drawerKeys()[2]}>
        <ListItemIcon>{navOptions.drawerIcons[2]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[2]} />
      </ListItem>
      <ListItem onClick={renderSign} button key={drawerKeys()[3]}>
        <ListItemIcon>{navOptions.drawerIcons[3]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[3]} />
      </ListItem>
      <ListItem onClick={renderAccounts} button key={drawerKeys[4]}>
        <ListItemIcon>{navOptions.drawerIcons[4]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[4]} />
      </ListItem>
      <ListItem onClick={renderRequests} button key={drawerKeys[5]}>
        <ListItemIcon>{navOptions.drawerIcons[5]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[5]} />
      </ListItem>
    </Fragment>
  );

  const maintenanceDrawerList = (
    <Fragment>
      <ListItem onClick={renderDashboard} button key={drawerKeys()[0]}>
        <ListItemIcon>{navOptions.drawerIcons[0]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[0]} />
      </ListItem>
      <ListItem onClick={renderCreateDoc} button key={drawerKeys()[1]}>
        <ListItemIcon>{navOptions.drawerIcons[1]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[1]} />
      </ListItem>
      <ListItem onClick={renderEdit} button key={drawerKeys()[2]}>
        <ListItemIcon>{navOptions.drawerIcons[2]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[2]} />
      </ListItem>
      <ListItem onClick={renderSign} button key={drawerKeys()[3]}>
        <ListItemIcon>{navOptions.drawerIcons[3]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[3]} />
      </ListItem>
      <ListItem onClick={renderLogs} button key={drawerKeys[4]}>
        <ListItemIcon>{navOptions.drawerIcons[4]}</ListItemIcon>
        <ListItemText primary={navOptions.drawerOptions[4]} />
      </ListItem>
    </Fragment>
  );

  const drawerList = (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {!adminMode
          ? userDrawerList
          : currentUser.uid === process.env.REACT_APP_SERVICE_ADMIN_ID
          ? maintenanceDrawerList
          : adminDrawerList}
      </List>
    </div>
  );
  //Drawer Logic Ends

  //Menu Logic Starts
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuItemClick = (index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    handleMenuClose();
    switch (navOptions.options[index]) {
      case 'Perfil':
        navigate('/perfil');
        break;
      case 'Configuración':
        navigate('/configuración_cuenta');
        break;
      case 'Cerrar sesión':
        logoutHandler();
        break;
      default:
        break;
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  //Menu Logic Ends

  //Tabs Logic Starts
  const tabChangeHandler = (_, newValue) => {
    setTabValue(newValue);
  };

  const toolbarTabs = () => {
    if (width >= theme.breakpoints.values.sm) {
      if (!adminMode) {
        return (
          <Tabs
            value={tabValue}
            onChange={tabChangeHandler}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Navigation tab"
          >
            <Tab
              onClick={renderDashboard}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[0]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[0]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderCreateDoc}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[1]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[1]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderEdit}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[2]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[2]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderSign}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[3]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[3]}
                  </p>
                </Grid>
              }
            />
          </Tabs>
        );
      } else if (currentUser.uid === process.env.REACT_APP_SERVICE_ADMIN_ID) {
        return (
          <Tabs
            value={tabValue}
            onChange={tabChangeHandler}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Navigation tab"
          >
            <Tab
              onClick={renderDashboard}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[0]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[0]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderCreateDoc}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[1]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[1]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderEdit}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[2]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[2]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderSign}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[3]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[3]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderLogs}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[4]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[4]}
                  </p>
                </Grid>
              }
            />
          </Tabs>
        );
      } else {
        return (
          <Tabs
            value={tabValue}
            onChange={tabChangeHandler}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Navigation tab"
          >
            <Tab
              onClick={renderDashboard}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[0]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[0]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderCreateDoc}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[1]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[1]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderEdit}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[2]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[2]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderSign}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[3]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[3]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderAccounts}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[4]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[4]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderRequests}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[5]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[5]}
                  </p>
                </Grid>
              }
            />
            <Tab
              onClick={renderLoadDocument}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[6]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[6]}
                  </p>
                </Grid>
              }
            />
            {/* <Tab
              onClick={renderPDFTrial}
              maxwidth="50px"
              className={classes.tab}
              label={
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  spacing={0}
                >
                  {navOptions.drawerIcons[7]}
                  <p className={classes.tabText}>
                    {navOptions.drawerOptions[7]}
                  </p>
                </Grid>
              }
            /> */}
          </Tabs>
        );
      }
    }
  };
  //Tabs Logic Ends

  const avatar = (
    <Avatar
      alt={`${currentUser.name} ${currentUser.lastName}`}
      src={userTab ? avatarSelectedPlaceholder : avatarPlaceholder}
      className={userTab ? classes.avatarOutline : classes.avatarOutlineOff}
    >
      {/* {`${name.charAt(0)}${lastName.charAt(0)}`} */}
    </Avatar>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      className={classes.menu}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 80, left: window.innerWidth }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {navOptions.options.map((option, index) => (
        <MenuItem
          key={option}
          selected={index === selectedIndex}
          onClick={() => handleProfileMenuItemClick(index)}
        >
          {option}
        </MenuItem>
      ))}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      className={classes.mobileMenu}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 70, left: window.innerWidth }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={enterProfile}>
        <ListItemIcon>{avatar}</ListItemIcon>
        <ListItemText primary="Perfil" />
      </MenuItem>
      <MenuItem onClick={enterConfig}>
        <ListItemIcon>
          <Settings />
        </ListItemIcon>
        <ListItemText primary="Configuración" />
      </MenuItem>
      <MenuItem onClick={logoutHandler}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Cerrar Sesión" />
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position="static" color="default">
        <Toolbar>
          {drawerButton()}
          <IconButton onClick={renderDashboard} className={classes.logoContainer} size="large">
            <NexumIcon className={classes.logo} />
          </IconButton>

          <div className={classes.grow} />
          {toolbarTabs()}
          <div className={classes.spacer} />
          <div className={classes.sectionDesktop}>
            <ModalNotification notificationTab={notificationTab} />
            <ModalUserMenu
              handleClick={handleProfileMenuOpen}
              userTab={userTab}
            />
          </div>
          <div className={classes.sectionMobile}>
            <ModalNotification notificationTab={notificationTab} />
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              size="large">
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <SwipeableDrawer
        anchor="left"
        open={drawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        {drawerList}
      </SwipeableDrawer>
    </div>
  );
};

export default NavBar;
