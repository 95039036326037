import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ToggleButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    // paddingTop: "30%",
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: "15%",
    // },
  },
  formContainer: {
    width: "100%",
  },
  buttons: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  labelsInfo: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "left",
  },
  extraInfo: {
    padding: 15,
  },
  gridContainer: {
    marginTop: 8
  },
  gridDesktop: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  gridMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  gridTextField: {
    marginTop: '.7rem',
  },
  roleDescription: {
    fontFamily: "Roboto",
    fontSize: "14px",
    textAlign: "left",
    margin: "10px 0px 10px 0px",
  },
  boxInputNameGroup: {
    textAlign: "initial",
  },
  boxInfoMember: {
    width: "100%",
    marginTop: "10px",
    position: "relative",
  },
  buttonWhite: {
    color: 'white'
  },
  emailMember: {
    paddingLeft: "15px",
  },
  iconColor: {
    [theme.breakpoints.only('xs')]: {
      color: 'white'
    }
  },
  roleMember: {
    paddingLeft: "15px",
  },
  deleteIcon: {
    marginTop: -6,
    marginRight: 5
  },
  textColor: {
    color: 'black',
    [theme.breakpoints.only('xs')]: {
      color: 'white'
    }
  },
  txtField: {
    fontFamily: 'Roboto',
    fontSize: 14,
    color: 'black',
    [theme.breakpoints.only('xs')]: {
      color: 'gray'
    }
  },
  select: {
    '& .MuiSelect-selectMenu': {

      [theme.breakpoints.only('xs')]: {
        backgroundColor: 'black'
      }
    }
  },
}));

export const OutlinedToggleBtn = withStyles(theme => ({
  root: {
    color: "#041854",
    borderColor: "#041854",
    [theme.breakpoints.only('xs')]: {
      color: 'white',
      borderColor: 'white',
      '&:hover': {
        borderColor: 'white',
      },
    },
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: alpha("#041854", 0.90),
      '&:hover': {
        backgroundColor: alpha("#041854", 0.75),
      },
      [theme.breakpoints.only('xs')]: {
        color: '#041854',
        backgroundColor: 'white',
        '&:hover': {
          backgroundColor: alpha("#ffffff", 0.75),
        },
      }
    },
  }}))(ToggleButton);

export const OutlinedButton = withStyles(theme => ({
  [theme.breakpoints.only('xs')]: {
    root: {
      color: 'white',
      borderColor: 'white',
      '&:hover': {
        borderColor: 'white',
      },
    },
  }
}))(Button);

export const WBTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: '#041854',
      [theme.breakpoints.only('xs')]: {
        color: 'white'
      }
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#041854',
      [theme.breakpoints.only('xs')]: {
        borderBottomColor: 'white'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
        }
      },
      '&:hover fieldset': {
        borderColor: '#041854',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
        }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#041854',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
        }
      },
    },
  },
}))(TextField);

export default useStyles;
