import dateFormat from 'dateformat';

const abbreviatedMonths = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic'
];

/**
 * Converts yyyy-mm-dd to shortened date display format
 * @param {*} date Date in yyyy-mm-dd
 * @returns {string} returns formatted date to display: <Dec 31, 2023>
 */
export const convertYYYYMMDDToDisplayDate = (date) => {
  if (!date) return null;
  let [year, month, day] = date.split('-');
  if (day.startsWith(0)) {
    day = day.substring(1);
  }
  const abbreviatedMonth = abbreviatedMonths[month - 1];
  const formattedDate = abbreviatedMonth + ' ' + day + ', ' + year;
  return formattedDate;
};

/**
 * Converts UNIX timestamp to dd/mm/yyyy format
 * @param {String} unixDate in UNIX format
 * @returns {String} returns formatted date to display: <31/12/2023>
 */
export const convertUnixTimestampToDDMMYYYY = (unixDate) => {
  let date = new Date(unixDate);
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0');
  let yyyy = date.getFullYear();

  date = dd + '/' + mm + '/' + yyyy;
  return date;
};

/**
 * Converts UNIX timestamp to shortened date display format
 * @param {String} unixDate in UNIX format
 * @returns {String} returns formatted date to display: <Dec 31, 2023>
 */
export const convertUnixTimestampToDisplayDate = (unixDate) => {
  if (!unixDate) return null;
  const date = new Date(unixDate);
  const year = date.getFullYear();
  const month = abbreviatedMonths[date.getMonth()];
  const day = date.getDate();
  const formattedDate = month + ' ' + day + ', ' + year;
  return formattedDate;
};

/**
 * Convert date format to dd/mm/yyyy
 * @param {Date} date
 * @returns {String} returns formatted date to display: <31/12/2023>
 */
export const dateFormatDDMMYYYY = (date) => {
  return dateFormat(date, 'dd/mm/yyyy')
}
