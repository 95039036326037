import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: '0 auto'
  },
  labelBlock: {
    display: 'block',
    padding: '4px 20px 0px 20px'
  },
  text: {
    margin: '0 0 2px 0',
    fontFamily: 'Roboto',
    fontSize: '10px',
    lineHeight: '1.5'
  },
  textOn: {
    margin: '0 0 2px 0',
    fontFamily: 'Roboto',
    fontSize: '10px',
    color: ' #f77c2e',
    lineHeight: '1.5'
  },
  dialogFormat: {
    position: 'fixed',
    top: '50px',
    right: '-15px',
    fontSize: '14px',
    fontFamily: 'roboto',
    [theme.breakpoints.only('xs')]: {
      top: '40px'
    }
  }
}));

export default useStyles;
