import React, { useState, Fragment } from 'react';
import momentTimezone from 'moment-timezone';
import {
  Button,
  Box,
  Grid,
  TextField,
  MenuItem,
  Typography
} from '@mui/material';
import useStyles from './RegisterStyles';
import { countries, states, cities } from './Location';
import { useAuth } from '../../../Contexts/AuthContext';
import { ReactComponent as RegisterNexumIcon } from '../../../static/icons/authIcons/Vector.svg';
import Notification from '../../../Helpers/Notification';
import {
  maxLengthName,
  maxLengthAddress,
  maxLengthPostalCode,
  maxLengthRFC,
  maxLengthPhone,
  minDate
} from '../../../Utilities/limitsInputs';
//show password
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

export const AuthContext = React.createContext();

const Register = ({ data, nextStep, goToLogin, handleChange }) => {
  const [correctInfo, setCorrectInfo] = useState({
    name: true,
    lastName: true,
    bday: true,
    company: true,
    cp: true,
    phone: true,
    mail: true,
    password: true
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });

  const [loading, setLoading] = useState(false);

  const { signUp } = useAuth();

  const [visibility, setVisibility] = useState({
    showPass: false,
    showConfirmPass: false
  });
  const handleClickShowPass = () => {
    setVisibility({
      ...visibility,
      showPass: !visibility.showPass
    });
  };
  const handleClickShowConfirmPass = () => {
    setVisibility({
      ...visibility,
      showConfirmPass: !visibility.showConfirmPass
    });
  };

  const handleSuccessfulAuth = () => {
    nextStep();
  };

  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
  };

  const [alignment, setAlignment] = React.useState('left');

  const [touched, setTouched] = useState(false);

  let date_now = momentTimezone.tz('America/Mexico_City').format('YYYY-MM-DD');

  const isDateSupported = function () {
    let input = document.createElement('input');
    const value = 'a';
    input.setAttribute('type', 'date');
    input.setAttribute('value', value);
    return input.value !== value;
  };

  const toInputUppercase = (e) => {
    e.target.value = ('' + e.target.value)
      .replace(/[^a-zA-Z0-9\u00f1\u00d1\&]/g, '')
      .toUpperCase();
  };

  const toInputOnlyNumbers = (e) => {
    e.target.value = ('' + e.target.value).replace(/[^0-9]/g, '');
  };
  const toInputOnlyLowerCase = (e) => {
    e.target.value = e.target.value.toLowerCase();
  };

  const onRegisterSubmit = async (e) => {
    e.preventDefault();
    const regexMail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    const regexDate =
      /^(19|[2-9][0-9])(00|04|08|12|16|20|24|28|32|36|40|44|48|52|56|60|64|68|72|76|80|84|88|92|96)-(02-29)$|^((19|[2-9][0-9])\d\d)-(((0[4,6,9]|11)-(29|30))|((0[13578]|1[02])-(29|30|31)$)|((0[1-9]|1[012])-((0[1-9]|1[0-9]|2[0-8]))$))/i;

    const onlyChar = /^[a-zA-ZÀ-ÿ]+(\s*[a-zA-ZÀ-ÿ]*)*[a-zA-ZÀ-ÿ]+$/;

    const onlyNum = /^\d+$/;

    if (data.password == '') {
      setNotify({
        isOpen: true,
        message: 'Campo de contraseña no puede estar vacío',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, password: false });
    } else if (data.password.length < 8) {
      setNotify({
        isOpen: true,
        message: 'La contraseña debe ser de 8 caracteres o más',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, password: false });
    } else if (data.password !== data.confirmPass) {
      setNotify({
        isOpen: true,
        message: 'Las contraseñas no coinciden',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, password: false });
    } else {
      setCorrectInfo({ ...correctInfo, password: true });
    }

    if (!data.mail) {
      setNotify({
        isOpen: true,
        message: 'Campo de correo no puede estar vacío',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, mail: false });
    } else if (!regexMail.test(data.mail)) {
      setNotify({
        isOpen: true,
        message: 'El correo tiene un formato no permitido',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, mail: false });
    } else {
      setCorrectInfo({ ...correctInfo, mail: true });
    }

    if (!onlyChar.test(data.name)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el nombre',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, name: false });
    } else {
      setCorrectInfo({ ...correctInfo, name: true });
    }

    if (!onlyChar.test(data.lastName)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en el apellido',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, lastName: false });
    } else {
      setCorrectInfo({ ...correctInfo, lastName: true });
    }

    if (!regexDate.test(data.bday)) {
      setNotify({
        isOpen: true,
        message: 'La fecha de nacimiento tiene un formato no permitido',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, bday: false });
    } else if (date_now < data.bday) {
      setNotify({
        isOpen: true,
        message: 'Seleccione una fecha de nacimiento válida',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, bday: false });
    } else {
      setCorrectInfo({ ...correctInfo, bday: true });
    }

    if (!onlyChar.test(data.company)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar letras en la compañía',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, company: false });
    } else {
      setCorrectInfo({ ...correctInfo, company: true });
    }

    if (!onlyNum.test(data.cp)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar números en el código postal',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, cp: false });
    } else {
      setCorrectInfo({ ...correctInfo, cp: true });
    }

    if (!onlyNum.test(data.phone)) {
      setNotify({
        isOpen: true,
        message: 'Solo se pueden colocar números en el teléfono',
        type: 'error'
      });
      setCorrectInfo({ ...correctInfo, phone: false });
    } else {
      setCorrectInfo({ ...correctInfo, phone: true });
    }

    if (
      data.password == data.confirmPass &&
      data.password.length > 7 &&
      correctInfo.name &&
      correctInfo.lastName &&
      correctInfo.bday &&
      correctInfo.company &&
      correctInfo.cp &&
      correctInfo.phone &&
      correctInfo.password &&
      correctInfo.mail
    ) {
      handleSuccessfulAuth();
    }
  };

  const renderLogin = () => {
    goToLogin();
  };

  let variable = data.state;

  return (
    <Grid container justifyContent="center" className={classes.colorMargins}>
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        justifyContent="center"
        xs={12}
        className={classes.profileCheck}
      >
        <Grid
          item
          justifyContent="center"
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <RegisterNexumIcon className={classes.icon} />
        </Grid>
        <Grid item justifyContent="center">
          <h2 className={classes.titleText}>Registro</h2>
        </Grid>
      </Grid>
      <form onSubmit={onRegisterSubmit}>
        <Box>
          <Grid
            className={classes.negTop}
            container
            item
            alignItems="flex-start"
            xs={12}
            spacing={0}
          >
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
              className={classes.topFields}
            >
              <p>Nombre</p>
              <TextField
                required
                id="name"
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthName,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                variant="outlined"
                name="name"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              className={classes.topFields2}
              sm={5}
              xs={12}
            >
              <p>Apellido</p>
              <TextField
                required
                id="lastName"
                variant="outlined"
                name="lastName"
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthName,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Fecha de Nacimiento</p>
              <TextField
                required
                id="bday"
                type="date"
                placeholder="AAAA-MM-DD"
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    min: minDate,
                    max: date_now,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                helperText={
                  !isDateSupported() && (
                    <Fragment>
                      <Typography variant="body2" className={classes.txtInfo}>
                        El formato debe ser AAAA-MM-DD
                      </Typography>
                      <Typography variant="body2" className={classes.txtInfo}>
                        Ej. 1980-04-26
                      </Typography>
                    </Fragment>
                  )
                }
                variant="outlined"
                name="bday"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Compañía</p>
              <TextField
                required
                id="company"
                variant="outlined"
                name="company"
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthName,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>País</p>
              <TextField
                required
                id="country"
                name="country"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                select
                InputProps={{
                  className: classes.txtField,
                  style: {
                    height: 30,
                    padding: '0 12px'
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {countries.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Estado</p>
              <TextField
                required
                id="state"
                name="state"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                select
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {states.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Ciudad</p>
              <TextField
                required
                disabled={!data.state}
                id="city"
                name="city"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                select
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              >
                {cities.map((cityoption) =>
                  cityoption[data.state]?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))
                )}
              </TextField>
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Código Postal</p>
              <TextField
                required
                id="cp"
                name="cp"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthPostalCode,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>RFC</p>
              <TextField
                required
                id="rfc"
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthRFC,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
                onInput={toInputUppercase}
                variant="outlined"
                name="rfc"
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Dirección</p>
              <TextField
                required
                id="address"
                variant="outlined"
                name="address"
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthAddress,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Correo</p>
              <TextField
                required
                id="mail"
                name="mail"
                variant="outlined"
                fullWidth
                onChange={handleChange}
                onInput={toInputOnlyLowerCase}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Teléfono*</p>
              <TextField
                required
                id="phone"
                name="phone"
                variant="outlined"
                placeholder="10 dígitos"
                fullWidth
                onInput={toInputOnlyNumbers}
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  inputProps: {
                    maxLength: maxLengthPhone,
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Contraseña</p>
              <TextField
                required
                id="password"
                name="password"
                variant="outlined"
                type={visibility.showPass ? 'text' : 'password'}
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPass}
                        edge="end"
                        size="large"
                      >
                        {visibility.showPass ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
            <Grid xs={2} />
            <Grid
              container
              item
              direction="column"
              alignItems="flex-start"
              sm={5}
              xs={12}
            >
              <p>Confirmar Contraseña</p>
              <TextField
                required
                name="confirmPass"
                id="confirmPass"
                type={visibility.showConfirmPass ? 'text' : 'password'}
                variant="outlined"
                fullWidth
                onChange={handleChange}
                InputProps={{
                  className: classes.txtField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPass}
                        edge="end"
                        size="large"
                      >
                        {visibility.showConfirmPass ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  inputProps: {
                    style: {
                      height: 30,
                      padding: '0 12px'
                    }
                  }
                }}
                InputLabelProps={{
                  shrink: true
                }}
              />
            </Grid>
          </Grid>
          <Grid container item justifyContent="center" spacing={1}>
            {/* <Grid item xs={12}>

              <FormControlLabel
                control={<Checkbox checked={checked} onChange={handleCheckChange} name="aviso" />}
                label='Acepto el aviso de confidencialidad'
              />
            </Grid> */}
            <Grid item xs={12}>
              <Button
                className={classes.buttonWhite}
                variant="contained"
                color="secondary"
                type="submit"
                disabled={
                  !data.name ||
                  !data.lastName ||
                  !data.bday ||
                  !data.company ||
                  !data.country ||
                  !data.state ||
                  !data.city ||
                  !data.cp ||
                  !data.rfc ||
                  !data.address ||
                  !data.mail
                }
                onClick={onRegisterSubmit}
              >
                Crear Cuenta
              </Button>
            </Grid>
            <Grid item xs={12} className={classes.posTop}>
              <p>
                Ya tengo una cuenta
                <b className={classes.colorChange2} onClick={renderLogin}>
                  Iniciar Sesión
                </b>
              </p>
            </Grid>
            {/* <p className={classes.colorChange2} >
              Consulta nuestra politica de privacidad
            </p> */}
          </Grid>
        </Box>
      </form>
      <Notification notify={notify} setNotify={setNotify} />
    </Grid>
  );
};

export default Register;
