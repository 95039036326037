import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import NavBar from '../components/Admin/NavBar/NavBar';
import { useAuth } from '../Contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (currentUser.uid !== process.env.REACT_APP_ADMIN_ID) {
    return <Navigate to="/" replace />;
  }

  return (
    <>
      <NavBar />
      { children }
    </>
  );
};

export default ProtectedRoute;
