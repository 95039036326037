import React from 'react';
import NavBar from '../components/Admin/NavBar/NavBar';
import EmptyNavBar from 'components/Guest/NavBar/EmptyNavBar';
import { useAuth } from '../Contexts/AuthContext';

const ProtectedGuestRoute = ({ children }) => {
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser ? <NavBar /> : <EmptyNavBar />}
      { children }
    </>
  );
};

export default ProtectedGuestRoute;
