import React from 'react';
import { CircularProgress } from '@mui/material';
import { Grid } from '@mui/material';

const Loading = () => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item xs={4}></Grid>

      <Grid
        style={{ position: 'absolute', top: '25%' }}
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={4}></Grid>
        <CircularProgress color="secondary" size={'20em'} />
      </Grid>
    </Grid>
  );
};

export default Loading;
