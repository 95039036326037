// Deprecated, start using firebase/firestore ^
import firebase, { db } from '../Firebase';
import {
  getStorageSpaceUsedByUserId,
  saveDocumentUser,
  deleteDocumentUser
} from './storage';
import { deletePackageRequest, getPackageRequest } from './UserPackageData';
import {
  query,
  where,
  collection,
  doc,
  getDocs,
  getDoc,
  getCountFromServer
} from 'firebase/firestore';
import { convertUnixTimestampToDisplayDate } from 'Utilities/formatDate';

let dbRef = db.collection('UserData');

const returnObjectUser = (userData, userId, userStorage) => {
  return {
    ...userData.data(),
    id: userId,
    StorageUsed: parseFloat(userStorage)
  };
};

export const getUsersWithSignatures = async () => {
  const dbCollection = collection(db, 'UserData');
  const q = query(dbCollection, where('signatures', '>=', 1));
  const querySnapshot = await getDocs(q);
  const usersWithSignatures = [];
  querySnapshot.forEach((doc) => {
    usersWithSignatures.push({ id: doc.id, data: doc.data() });
  });

  const usersWithSignaturesData = usersWithSignatures.map(({ id, data }) => ({
    id,
    name: data.name,
    mail: data.mail,
    signatures: data.signatures
  }));

  return usersWithSignaturesData;
};

export const getTotalUsersWithSignatures = async () => {
  const dbCollection = collection(db, 'UserData');
  const q = query(dbCollection, where('signatures', '>=', 1));
  const snapshot = await getCountFromServer(q);
  return snapshot.data().count;
};

const returnPersonalInformationUserObject = (data) => {
  return {
    address: {
      line1: data.address.line1,
      country: data.address.country,
      state: data.address.state,
      city: data.address.city,
      zipCode: data.address.zipCode
    },
    birthday: data.birthday,
    rfc: data.rfc,
    phone: data.phone,
    idCard: data.idCard
      ? {
          url: data.idCard.url ? data.idCard.url : '',
          name: data.idCard.name ? data.idCard.name : '',
          time: data.idCard.time ? data.idCard.time : 0
        }
      : {
          url: '',
          name: '',
          time: 0
        },
    idCardReverse: data.idCardReverse
      ? {
          url: data.idCardReverse.url ? data.idCardReverse.url : '',
          name: data.idCardReverse.name ? data.idCardReverse.name : '',
          time: data.idCardReverse.time ? data.idCardReverse.time : 0
        }
      : {
          url: '',
          name: '',
          time: 0
        }
  };
};

export const createNewUser = async (data, uid) => {
  let response = {};

  await dbRef
    .doc(uid)
    .set({
      name: data.name,
      lastName: data.lastName,
      signupDate: Date.now(),
      mail: data.mail,
      package: 'Gratis',
      signatures: 0,
      signaturesUsed: 0,
      storageLimit: 200
    })
    .then(() => {
      return dbRef
        .doc(uid)
        .collection('PersonalInformation')
        .doc('PersonalInformation')
        .set({
          address: {
            line1: data.address,
            country: data.country,
            state: data.state,
            city: data.city,
            zipCode: data.cp
          },
          birthday: data.bday,
          rfc: data.rfc,
          phone: data.phone
        });
    })
    .then(() => {
      response = {
        type: 'success',
        message: 'Se creo el usuario correctamente'
      };
    })
    .catch((error) => {
      response = {
        message: error.message,
        type: 'error'
      };
    });

  return response;
};

export const getPersonalInformation = async (userId) => {
  const personalInfoRef = getPersonalInformationDocumentUser(
    userId,
    'PersonalInformation'
  );

  const personalDataRef = await personalInfoRef.get().catch((error) => {
    throw error.message;
  });
  const personalData = returnPersonalInformationUserObject(
    personalDataRef.data()
  );
  return personalData;
};

const getPersonalInformationDocumentUser = (userId, doc) => {
  return dbRef.doc(userId).collection('PersonalInformation').doc(doc);
};

export const getUserData = async (userId) => {
  const userData = await dbRef.doc(userId).get();
  return returnObjectUser(userData, userId, 0);
};

export const getUserDataByEmail = async (mail) => {
  const q = query(collection(db, 'UserData'), where('mail', '==', mail));
  const querySnapshot = await getDocs(q);
  let manager;
  querySnapshot.forEach((doc) => {
    manager = { uid: doc.id, ...doc.data() };
  });
  return manager;
};

export const getUserDataWithStorage = async (userId) => {
  const userData = await dbRef.doc(userId).get();
  let userStorage = await getStorageSpaceUsedByUserId(userId);
  return returnObjectUser(userData, userId, userStorage);
};

export const getUserStorageUsed = async (userId) => {
  const userStorage = await getStorageSpaceUsedByUserId(userId);
  return parseFloat(userStorage);
};

export const saveSignatureToUser = async (
  userId,
  signatureTuple,
  signatureBase64
) => {
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'SignatureData'
  );
  await dbReference.set(
    {
      signatureTuple: signatureTuple,
      signatureBase64: signatureBase64
    },
    { merge: true }
  );
};

export const saveIdCardUser = async (userId, fileToSave) => {
  const savedDoc = await saveDocumentUser(userId, fileToSave, 'idCard');
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'PersonalInformation'
  );
  await dbReference.set(
    {
      idCard: savedDoc
    },
    { merge: true }
  );
  return savedDoc;
};

export const saveIdCardReverseUser = async (userId, fileToSave) => {
  const savedDoc = await saveDocumentUser(userId, fileToSave, 'idCardReverse');
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'PersonalInformation'
  );
  await dbReference.set(
    {
      idCardReverse: savedDoc
    },
    { merge: true }
  );
  return savedDoc;
};

export const deleteIdCardUser = async (userId, file) => {
  await deleteDocumentUser(userId, file, 'idCard');
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'PersonalInformation'
  );
  await dbReference.update({
    idCard: { url: '', name: '' }
  });
};

export const deleteIdCardReverseUser = async (userId, file) => {
  await deleteDocumentUser(userId, file, 'idCardReverse');
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'PersonalInformation'
  );
  await dbReference.update({
    idCardReverse: { url: '', name: '' }
  });
};

export const addStarredDocToUser = async (userId, docId) => {
  await dbRef.doc(userId).update({
    starredDocs: firebase.firestore.FieldValue.arrayUnion(docId)
  });
};

export const deleteStarredDocToUser = async (userId, docId) => {
  await dbRef.doc(userId).update({
    starredDocs: firebase.firestore.FieldValue.arrayRemove(docId)
  });
};

export const updateUserData = async (userId, newData) => {
  try {
    const user = dbRef.doc(userId);
    return await user.update(newData);
  } catch (error) {
    throw error;
  }
};

export const updateUserPersonalInformationData = async (userId, newData) => {
  await dbRef
    .doc(userId)
    .collection('PersonalInformation')
    .doc('PersonalInformation')
    .update(newData);
};

export const updateUserSignatures = async (
  userId,
  newSignatures,
  userStorageLimit
) => {
  await dbRef
    .doc(userId)
    .update({
      signatures: firebase.firestore.FieldValue.increment(newSignatures),
      storageLimit: userStorageLimit
    })
    .catch((error) => {
      throw error.message;
    });
};

export const getUserListComplete = async () => {
  let usersList = await getAllUsers();

  const getUserPhone = async (list) => {
    const userDocs = await Promise.all(
      usersList.map((user) =>
        getDoc(
          query(
            doc(
              collection(
                doc(collection(db, 'UserData'), user.id),
                'PersonalInformation'
              ),
              'PersonalInformation'
            )
          )
        )
      )
    );

    return list.map((user, i) => {
      if (!userDocs[i].data()) {
        return null;
      }
      return (user.data.phone = userDocs[i].data().phone);
    });
  };

  return getUserPhone(usersList).then(() => {
    usersList = usersList.map(({ id, data }) => ({
      id: id,
      name: data.name ? data.name : '-',
      mail: data.mail ? data.mail.toLowerCase() : '-',
      signupDate: data.signupDate ? data.signupDate : null,
      signatures: data.signatures ? data.signatures : 0,
      signaturesUsed: data.signaturesUsed ? data.signaturesUsed : 0,
      phone: data.phone ? data.phone : '-',
      hasSignatures: data.signatures ? 'true' : 'false'
    }));

    usersList.forEach((user) => {
      user.date = user.signupDate
        ? convertUnixTimestampToDisplayDate(user.signupDate)
        : '-';
      if (user.signatures === 0 && user.signaturesUsed === 0) {
        user.signaturesDisplay = '-';
      } else {
        user.signaturesDisplay =
          user.signatures + '/' + (user.signatures + user.signaturesUsed);
      }
    });

    return usersList;
  });
};

export const getAllUsers = async () => {
  const dbCollection = collection(db, 'UserData');
  const q = query(dbCollection);

  const querySnapshot = await getDocs(q);
  const usersList = [];
  querySnapshot.forEach((doc) => {
    usersList.push({ id: doc.id, data: doc.data() });
  });

  return usersList;
};

export const updateUserPackageRequest = async (userId, newReq, pckgType) => {
  const userData = (await getUserData(userId)).data();
  const userRequest = userData.PackageRequest;
  // if PackageRequest exists delete if it hasnt been checked
  if (userRequest) {
    const packageData = await getPackageRequest(userRequest);
    if (packageData.Checked === false) {
      await deletePackageRequest(userRequest);
    }
  }
  await dbRef.doc(userId).update({
    PackageRequest: newReq
  });
};

export const getSignatureUser = async (userId) => {
  const dbReference = getPersonalInformationDocumentUser(
    userId,
    'SignatureData'
  );
  const signatureData = await dbReference.get();

  return returnObjectUserSignature(signatureData.data());
};

export const returnObjectUserSignature = (signature) => {
  if (signature) {
    return {
      signatureTuple: signature.signatureTuple,
      signatureBase64: signature.signatureBase64
    };
  } else {
    return {
      signatureTuple: null,
      signatureBase64: null
    };
  }
};

export const getTotalUsersRegistered = async () => {
  const coll = collection(db, 'UserData');
  const snapshot = await getCountFromServer(coll);
  return snapshot.data().count;
};

// DANGER: THIS FUNCTION SHOULD ONLY BE USED TO UPDATE THE DATABASE WHEN MERGING IN MASTER
// Update all the users with the total of signatures used
// export const updateUsersWithTotalSignaturesUsed = async () => {
//   let usersWithSignaturesUsed = {};

//   const users = await dbRef.get();

//   users.forEach((userDoc) => {
//     const user = userDoc.data();

//     if (usersWithSignaturesUsed[userDoc.id] == undefined) {
//       usersWithSignaturesUsed[userDoc.id] = user;
//     }
//   });

//   let countUsers = 0;
//   let totalDocsSigned = 0;

//   const lastUserId = Object.keys(usersWithSignaturesUsed)[
//     Object.keys(usersWithSignaturesUsed).length - 1
//   ];

//   Object.keys(usersWithSignaturesUsed).forEach(async (userDocId) => {
//     countUsers = countUsers + 1;

//     const documentsSigned = await db
//       .collection('DocumentsData')
//       .where('initiatorUserId', '==', userDocId)
//       .where('status', 'in', ['finished', 'published'])
//       .get();

//     const userSignedDocsCount = documentsSigned.docs.length;
//     totalDocsSigned = totalDocsSigned + userSignedDocsCount;

//     const userRef = dbRef.doc(userDocId);

//     try {
//       await userRef.update({
//         signaturesUsed: userSignedDocsCount
//       });
//       console.log(
//         `Updated user ${userDocId} with ${userSignedDocsCount} total signatures used`
//       );
//     } catch (error) {
//       console.log(error);
//     }

//     if (lastUserId === userDocId) {
//       console.log(`DONE! Updated ${countUsers} users!`);
//       console.log(`TOTAL DOCS SIGNED: ${totalDocsSigned}`);
//     }
//   });
// };
