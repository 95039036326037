import { createStore, combineReducers } from "redux";
import newDocumentReducer from "./newDocument/reducer";
import listDocumentsReducer from "./listDocuments/reducer";
import userDataReducer from "./userData/reducer";

const reducers = combineReducers({
  newDocumentReducer,
  listDocumentsReducer,
  userDataReducer,
});

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
