const initialState = {
  toEdit: [],
  toSign: [],
  published: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_DOCS_USER_TO_EDIT":
      return {
        ...state,
        toEdit: state.toEdit.concat(payload),
      };
    case "SAVE_DOCS_USER_TO_SIGN":
      return {
        ...state,
        toSign: state.toSign.concat(payload),
      };
    case "SAVE_DOCS_USER_PUBLISHED":
      return {
        ...state,
        published: state.published.concat(payload),
      };
    case "STAR_DOCS":
      let temp_to_edit = state.toEdit.map((docToEdit) => {
        let doc_is_starred = payload.includes(docToEdit.id);
        return {
          ...docToEdit,
          starred: doc_is_starred,
        };
      });
      return {
        ...state,
        toEdit: [...temp_to_edit],
      };
    case "STAR_DOC_TO_EDIT":
      let temp_state_to_edit = [...state.toEdit];
      let indexObjToStar = temp_state_to_edit.findIndex(
        (obj) => obj.id == payload
      );
      let status = temp_state_to_edit[indexObjToStar].starred;
      temp_state_to_edit[indexObjToStar].starred = !status;
      return {
        ...state,
        toEdit: temp_state_to_edit,
      };
    case "STAR_DOC_TO_SIGN":
      let temp_state_to_sign = [...state.toSign];
      let indexObjToStarSign = temp_state_to_sign.findIndex(
        (obj) => obj.id == payload
      );
      let statusSign = temp_state_to_sign[indexObjToStarSign].starred;
      temp_state_to_sign[indexObjToStarSign].starred = !statusSign;
      return {
        ...state,
        toSign: temp_state_to_sign,
      };
    case "STAR_DOC_PUBLISHED":
      let temp_state_published = [...state.published];
      let indexObjToStarPublished = temp_state_published.findIndex(
        (obj) => obj.id == payload
      );
      let statusPublished = temp_state_published[indexObjToStarPublished].starred;
      temp_state_published[indexObjToStarPublished].starred = !statusPublished;
      return {
        ...state,
        published: temp_state_published,
      };
    case "RESET_DOCS_USER":
      return initialState;

    default:
      return state;
  }
};

export const listDocuments = (state) => state.listDocumentsReducer;
