const initialState = {
  addendums: [],
  amount: '',
  contractingParty: '',
  countAccepted: 0,
  countSignatures: 0,
  createdDate: '',
  currency: 'MXN',
  description: '',
  docId: '',
  editors: [],
  editorsEmails: [],
  endDate: '',
  extraDocs: [],
  file: {
    url: '',
    name: '',
    time: ''
  },
  guestSignatureEnabled: false,
  hasSignatureOrder: false,
  initiatorUserId: '',
  name: '',
  owner: {},
  status: 'draft',
  signatureDate: '',
  signatureType: {
    digital: false,
    fiel: true,
    simple: false
  },
  signers: [],
  signersEmails: [],
  signersCoordinates: [],
  totalSignatures: 0,
  totalToAccept: 0,
  type: 'contrato',
  version: 1
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case 'RESET_NEW_DOC':
      return initialState;
    case 'MODIFY_NEW_DOC':
      return {
        ...state,
        [payload.prop]: payload.value
      };
    case 'ADD_FILE_NEW_DOC':
      return {
        ...state,
        file: {
          url: payload.url,
          name: payload.name,
          time: payload.time
        }
      };
    case 'DELETE_FILE_NEW_DOC':
      return {
        ...state,
        file: {
          url: '',
          name: '',
          userId: ''
        }
      };
    case 'ADD_EXTRA_FILE_NEW_DOC':
      let newExtraFile = {
        url: payload.url,
        name: payload.name,
        time: payload.time
      };

      return {
        ...state,
        extraDocs: [].concat(state.extraDocs, newExtraFile)
      };
    case 'DELETE_EXTRA_FILE_NEW_DOC':
      let new_extra_documents = [...state.extraDocs];
      new_extra_documents.splice(payload, 1);
      return {
        ...state,
        extraDocs: new_extra_documents
      };
    case 'ADD_ADDENDUM_NEW_DOC':
      let newAddendum = {
        url: payload.url,
        name: payload.name,
        time: payload.time
      };
      return {
        ...state,
        addendums: [].concat(state.addendums, newAddendum)
      };
    case 'DELETE_ADDENDUM_NEW_DOC':
      let new_addendums = [...state.addendums];
      new_addendums.splice(payload, 1);
      return {
        ...state,
        addendums: new_addendums
      };
    case 'MODIFY_HAS_SIGNATURE_ORDER':
      if (payload === true || payload === false) {
        return {
          ...state,
          hasSignatureOrder: payload
        };
      }
      break;
    case 'MODIFY_SIGNATURE_TYPE_NEW_DOC':
      let temp_signature_type = {
        ...state.signatureType,
        [payload.prop]: payload.value
      };
      if (
        temp_signature_type.digital === false &&
        temp_signature_type.fiel === false &&
        temp_signature_type.simple === false
      ) {
        return {
          ...state,
          signatureType: {
            ...temp_signature_type,
            fiel: state.signatureType.fiel,
            digital: state.signatureType.digital,
            simple: state.signatureType.simple
          }
        };
      } else {
        return {
          ...state,
          signatureType: temp_signature_type
        };
      }
    // case "MODIFY_RANGE_PAGES_SIGNATURE_NEW_DOC":
    //   return {
    //     ...state,
    //     rangePagesSignature: {
    //       ...state.rangePagesSignature,
    //       [payload.prop]: payload.value,
    //     },
    //   };
    // case "MODIFY_EXTRA_FILES_TYPE_NEW_DOC":
    //   return {
    //     ...state,
    //     idCardRequired: payload.value,
    //   };
    case 'GET_COUNT_APPROVERS':
      let list_approvers = state.editors.filter(
        (editor) => editor.role === 'approver'
      );
      return {
        ...state,
        totalToAccept: list_approvers.length
      };
    case 'ADD_EDITOR_NEW_DOC':
      return {
        ...state,
        editors: [...state.editors, payload],
        editorsEmails: [...state.editorsEmails, payload.email]
      };
    case 'MODIFY_EDITOR_NEW_DOC':
      let newEditors = [...state.editors];
      newEditors[payload.index][payload.prop] = payload.value;
      let newEditorsEmails = [...state.editorsEmails];
      if (payload.prop === 'email') {
        newEditorsEmails[payload.index] = payload.value;
      }
      return {
        ...state,
        editors: newEditors,
        editorsEmails: newEditorsEmails
      };
    case 'MODIFY_EDITOR_ROLE_NEW_DOC':
      let newEditorsTemp = [...state.editors];
      const editorToEdit = newEditorsTemp[payload.index];
      if (payload.value === 'approver') {
        newEditorsTemp[payload.index] = {
          name: editorToEdit.name,
          email: editorToEdit.email,
          role: payload.value,
          approved: false
        };
      } else if (payload.value === 'observer') {
        newEditorsTemp[payload.index] = {
          name: editorToEdit.name,
          email: editorToEdit.email,
          role: payload.value
        };
      }
      return {
        ...state,
        editors: newEditorsTemp
      };
    case 'DELETE_EDITOR_NEW_DOC':
      let listEditors = [...state.editors];
      listEditors.splice(payload, 1);
      let listEditorsEmails = [...state.editorsEmails];
      listEditorsEmails.splice(payload, 1);
      return {
        ...state,
        editors: listEditors,
        editorsEmails: listEditorsEmails
      };
    case 'ADD_SIGNATURE_NEW_DOC':
      return {
        ...state,
        signers: [...state.signers, payload],
        signersEmails: [...state.signersEmails, payload.email]
      };
    case 'MODIFY_SIGNATURE_NEW_DOC':
      let newSignatures = [...state.signers];
      if (payload.prop === 'notification') {
        newSignatures[payload.index][payload.prop] = payload.value;
      } else if (payload.prop === 'order') {
        let element_with_old_order = newSignatures.find(
          (x) => x.order === payload.value
        );
        let element_with_new_order = newSignatures[payload.index];
        let temp_order = element_with_new_order.order;
        element_with_new_order.order = element_with_old_order.order;
        element_with_old_order.order = temp_order;
      } else {
        newSignatures[payload.index][payload.prop] = payload.value;
      }
      let newSignaturesEmails = [...state.signersEmails];
      if (payload.prop === 'email') {
        newSignaturesEmails[payload.index] = payload.value;
      }
      return {
        ...state,
        signers: newSignatures,
        signersEmails: newSignaturesEmails
      };
    case 'DELETE_SIGNATURE_NEW_DOC':
      let list_signature = [...state.signers];
      let element_to_delete = list_signature.splice(payload, 1)[0];
      let listSignersEmails = [...state.signersEmails];
      const emailIndexToDelete = listSignersEmails.findIndex(
        (value) => value === element_to_delete.email
      );
      listSignersEmails.splice(emailIndexToDelete, 1);
      if (element_to_delete.order === list_signature.length + 1) {
        return {
          ...state,
          signers: list_signature,
          signersEmails: listSignersEmails
        };
      } else {
        list_signature.forEach((signature) => {
          if (signature.order > element_to_delete.order) {
            signature.order = signature.order - 1;
          }
        });
        return {
          ...state,
          signers: list_signature,
          signersEmails: listSignersEmails
        };
      }

    default:
      return state;
  }
};

export default reducer;
export const newDocument = (state) => state.newDocumentReducer;
