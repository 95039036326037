import React, { useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import useStyles, { WBTextField } from './VerifyAccountStyles';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Notification from '../../../Helpers/Notification';
import { useAuth } from '../../../Contexts/AuthContext';
import { maxLengthPhone } from '../../../Utilities/limitsInputs';

const VerifyAccount = (props) => {
  const {
    nextStep,
    setWaitLoad,
    data,
    verification,
    handleUserChange,
    addVerifier,
    setTemporalUser
  } = props;

  const { currentUser } = useAuth();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: ''
  });
  const [loading, setLoading] = useState(false);

  const handlePhoneChange = (e) => {
    handleUserChange(e);
  };

  const toInputOnlyNumbers = (e) => {
    e.target.value = ('' + e.target.value).replace(/[^0-9]/g, '');
  };

  const setUpRecaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
        callback: function (response) {
          handleOnSubmit();
        }
      }
    );
  };

  const handleError = () => {
    setLoading(true);
    setNotify({
      isOpen: true,
      message: 'Error al enviar código, intenta más tarde',
      type: 'error'
    });
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (data.phone == '') {
      setNotify({
        isOpen: true,
        message: 'Campo de teléfono no puede estar vacío',
        type: 'error'
      });
    } else if (data.phone.length < 10) {
      setNotify({
        isOpen: true,
        message: 'El teléfono debe contener 10 dígitos',
        type: 'error'
      });
    } else {
      setLoading(true);
      setTemporalUser(currentUser);
      setUpRecaptcha();

      const phoneNumber = '+52' + data.phone;
      const phoneCodeVerifier = new firebase.auth.PhoneAuthProvider();
      let appVerifier = window.recaptchaVerifier;
      phoneCodeVerifier
        .verifyPhoneNumber(phoneNumber, appVerifier)
        .then((verificationId) => {
          addVerifier(verificationId);
          setWaitLoad(true);
          setTimeout(() => {
            setWaitLoad(false);
          }, 10000);
          nextStep();
          setLoading(false);
        })
        .catch((error) => {
          handleError();
          setLoading(false);
        });
    }
  };

  const classes = useStyles();
  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container justifyContent="center" className={classes.root}>
        <div id="recaptcha-container"></div>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={8}
          className={classes.textColor}
        >
          <Grid item container justifyContent="center">
            <Typography
              variant="h5"
              className={classes.textColor}
              style={{ fontWeight: 'bold', marginBottom: '2rem' }}
            >
              {'Verifica tu cuenta'}
            </Typography>
          </Grid>
          <Grid
            container
            item
            direction="column"
            alignItems="flex-start"
            style={{ marginBottom: '1rem' }}
          >
            <Typography className={classes.text}>
              {'Enviar código a'}
            </Typography>
          </Grid>
          <WBTextField
            required
            label="Teléfono"
            id="phone-textfield"
            name="phone"
            value={data.phone}
            placeholder="10 dígitos"
            variant="outlined"
            inputProps={{
              maxLength: maxLengthPhone
            }}
            InputProps={{
              className: classes.txtField
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.textColor
            }}
            onInput={toInputOnlyNumbers}
            onChange={(event) => handlePhoneChange(event)}
            fullWidth
          />
          <Grid
            container
            item
            justifyContent="center"
            spacing={1}
            className={classes.buttons}
          >
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                disabled={loading}
                className={classes.buttonWhite}
                onClick={handleOnSubmit}
              >
                {'Enviar'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
    </form>
  );
};

export default VerifyAccount;
