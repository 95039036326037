import * as React from "react";
import { SvgIcon } from '@mui/material'

function SvgIconFirmar(props) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <switch>
          <g>
            <path d="M23.4 7.6c-.8-.8-2.2-.8-3 0l-2.1 2.1V4.1c0-.6-.2-1.1-.6-1.5l-2-2c-.4-.4-1-.6-1.5-.6H2.1C.9 0 0 .9 0 2.1v19.8C0 23.1.9 24 2.1 24h14.1c1.2 0 2.1-.9 2.1-2.1v-6.3l5.1-5.1c.8-.8.8-2.1 0-2.9zm-9.3-6.2c.1 0 .4 0 .6.2l2 2c.2.2.2.5.2.6h-2.8V1.4zm2.8 20.5c0 .4-.3.7-.7.7H2.1c-.4 0-.7-.3-.7-.7V2.1c0-.4.3-.7.7-.7h10.5v3.5c0 .4.3.7.7.7h3.5v5.5l-2.1 2.1-1 1c-.1.1-.1.2-.2.3l-1 3c-.1.3 0 .5.2.7.2.2.5.3.7.2l3-1c.1 0 .2-.1.3-.2l.1-.1.1 4.8zm-1.6-7.3l1 1-.4.4-1.5.5.5-1.5.4-.4zm2 0l-1-1 3.4-3.4 1 1-3.4 3.4zm5.1-5.1l-.7.7-1-1 .7-.7c.3-.3.7-.3 1 0 .3.3.3.8 0 1z" />
            <path d="M13.4 7H3.5c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h9.8c.4 0 .7-.3.7-.7.1-.4-.3-.7-.6-.7zM10.5 9.8h-7c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h7c.4 0 .7-.3.7-.7.1-.3-.3-.7-.7-.7zM10.5 12.7h-7c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h7c.4 0 .7-.3.7-.7.1-.4-.3-.7-.7-.7zM10.5 15.5h-7c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h7c.4 0 .7-.3.7-.7.1-.4-.3-.7-.7-.7zM13.4 19.8H9.1c-.4 0-.7.3-.7.7s.3.7.7.7h4.2c.4 0 .7-.3.7-.7s-.3-.7-.6-.7z" />
          </g>
        </switch>
      </svg>
    </SvgIcon>

  );
}

export default SvgIconFirmar;
