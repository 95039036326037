const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "SAVE_USER_DATA":
      let temp_state = {
        ...payload,
      };
      return temp_state;

    case "LOGOUT_USER":
      return initialState;

    default:
      return state;
  }
};

export const userData = (state) => state.userDataReducer;
