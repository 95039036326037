import React, { Fragment, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { auth } from '../Utilities/Firebase';
import Notification from '../Helpers/Notification';
import { saveUserData, logOutUser } from '../store/userData/action';
import { createNewUser, getUserData } from 'Utilities/Firebase/UserData';

const AuthContext = React.createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = connect(null, { saveUserData, logOutUser })(
  ({ children }) => {
    const [currentUser, setCurrentUser] = useState();
    const [loading, setLoading] = useState(true);
    const [notify, setNotify] = useState({
      isOpen: false,
      message: '',
      type: ''
    });

    const signUp = async (data) => {
      const cred = await auth.createUserWithEmailAndPassword(
        data.mail,
        data.password
      );

      const response = await createNewUser(data, cred.user.uid);

      setNotify({
        isOpen: true,
        message: response.message,
        type: response.type
      });
    };

    const login = (data) => {
      return auth
        .signInWithEmailAndPassword(data.mail, data.password)
        .then(async (user) => {
          setNotify({
            isOpen: true,
            message: 'Se inició sesión correctamente',
            type: 'success'
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: 'Usuario y/o contraseña incorrectos',
            type: 'error'
          });
        });
    };

    const logout = () => {
      return auth
        .signOut()
        .then(() => {
          setNotify({
            isOpen: true,
            message: 'Sesión terminada correctamente',
            type: 'success'
          });
          logOutUser();
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: error.message,
            type: 'error'
          });
        });
    };

    const reauthenticate = async (currentPassword) => {
      const user = firebase.auth().currentUser;
      const credential = await firebase.auth.EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      const reauth = await user.reauthenticateWithCredential(credential);
      return reauth;
    };

    const forgottenPassword = async (data) => {
      return auth
        .sendPasswordResetEmail(data)
        .then(async () => {
          setNotify({
            isOpen: true,
            message:
              'Se ha enviado correo de restablecimiento de contraseña correctamente a ' +
              data,
            type: 'success'
          });
        })
        .catch((err) => {
          console.log(err);
          setNotify({
            isOpen: true,
            message: 'Error al enviar correo de restablecimiento de contraseña',
            type: 'error'
          });
        });
    };

    const resetPassword = async (data) => {
      return auth
        .confirmPasswordReset(data.oobCode, data.newPassword)
        .then(async () => {
          setNotify({
            isOpen: true,
            message: 'Se ha restablecido la contraseña correctamente',
            type: 'success'
          });
        })
        .catch((err) => {
          console.log(err);
          setNotify({
            isOpen: true,
            message: 'Error al restablecer contraseña',
            type: 'error'
          });
        });
    };

    useEffect(() => {
      const fetchData = async () => {
        return auth.onAuthStateChanged(async (user) => {
          setCurrentUser(user);
          setLoading(false);
          if (user) {
            const userData = await getUserData(user.uid).catch((error) => {
              return error;
            });
            const tempUser = {
              ...userData,
              uid: user.uid,
              email: user.email
            };
            setCurrentUser(tempUser);
          }
        });
      };

      fetchData();
    }, []);

    const value = {
      currentUser,
      signUp,
      login,
      logout,
      reauthenticate,
      forgottenPassword,
      resetPassword
    };

    return (
      <Fragment>
        <AuthContext.Provider value={value}>
          {!loading && children}
        </AuthContext.Provider>
        <Notification notify={notify} setNotify={setNotify} />
      </Fragment>
    );
  }
);
