import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1
  },
  logo: {
    display: 'flex'
  },
  logoContainer: {
    backgroundColor: 'transparent !important'
  }
}));

export default useStyles;
