import * as React from 'react';
import { SvgIcon } from '@mui/material';

function SvgIconNotification({ color }) {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="25"
        width="25"
        viewBox="0 0 20 22"
      >
        <switch>
          <g>
            <path
              d="M16.3259 15.3276C16.244 15.3276 16.3259 15.3276 16.3259 15.3276C15.0986 14.264 14.3622 12.7094 14.3622 11.1549V10.2549C14.3622 9.92763 14.1168 9.68217 13.7895 9.68217C13.4622 9.68217 13.2168 9.92763 13.2168 10.2549V11.1549C13.2168 13.1185 14.1168 15.0004 15.5895 16.3094C15.5895 16.3094 15.6713 16.3913 15.6713 16.4731C15.6713 16.5549 15.5895 16.6367 15.5077 16.6367H1.84403C1.76222 16.6367 1.6804 16.5549 1.6804 16.4731C1.6804 16.3913 1.6804 16.3913 1.76222 16.3094C3.23494 15.0004 4.13494 13.1185 4.13494 11.1549V8.86399C4.13494 6.40945 6.1804 4.36399 8.63494 4.36399C8.71676 4.36399 8.79858 4.36399 8.79858 4.36399C9.12585 4.44581 9.45312 4.20036 9.53494 3.87308C9.61676 3.54581 9.37131 3.21854 9.04403 3.13672C8.8804 3.13672 8.79858 3.13672 8.63494 3.13672C5.44403 3.13672 2.90767 5.67308 2.90767 8.86399V11.1549C2.90767 12.7094 2.25313 14.264 1.02585 15.3276C1.02585 15.3276 0.944034 15.3276 0.944034 15.4094C0.616761 15.6549 0.453125 15.9822 0.453125 16.4731C0.453125 17.2913 1.10767 17.864 1.84403 17.864H5.60767C5.85313 19.2549 7.16222 20.3185 8.63494 20.3185C10.1077 20.3185 11.3349 19.2549 11.6622 17.864H15.4259C16.244 17.864 16.8168 17.2094 16.8168 16.4731C16.8168 15.9822 16.6531 15.6549 16.3259 15.3276ZM8.63494 19.0913C7.81676 19.0913 7.16222 18.6004 6.91676 17.864H10.3531C10.1077 18.6004 9.45312 19.0913 8.63494 19.0913Z"
              fill={color}
            />
            <path
              d="M14.3643 0.681641C12.0734 0.681641 10.2734 2.48164 10.2734 4.77255C10.2734 7.06346 12.0734 8.86346 14.3643 8.86346C16.6553 8.86346 18.4553 7.06346 18.4553 4.77255C18.4553 2.48164 16.5734 0.681641 14.3643 0.681641ZM14.3643 7.63619C12.8098 7.63619 11.5007 6.3271 11.5007 4.77255C11.5007 3.218 12.8098 1.90891 14.3643 1.90891C15.9189 1.90891 17.228 3.218 17.228 4.77255C17.228 6.3271 15.9189 7.63619 14.3643 7.63619Z"
              fill={color}
            />
          </g>
        </switch>
      </svg>
    </SvgIcon>
  );
}

export default SvgIconNotification;
