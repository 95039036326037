import React from 'react';
import { Button, Grid, Box, IconButton, Typography, Divider, Link } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useStyles, { OutlinedButton, WBTextField } from './CreateGroupMembersStyles';
import UseWindowDimensions from '../../../../customHooks/UseWindowDimensions';

const initiatiorLimit = 2

const CreateGroupMembers = ({
    newMember,
    groupMembers,
    handleNewMember,
    addNewMember,
    removeMember,
    prevStep,
    createGroup
}) => {

    const { width, theme } = UseWindowDimensions();

    // Controller Starts
    function validateEmail(email) {
        if (email == "") {
            return true;
        } else {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }
    }
    // Controller Ends
    // View Starts
    const classes = useStyles();

    const gridBuySignatures = (
        <Grid item xs={12} container className={classes.gridContainer} >
            <Grid item xs={12} >
                <Divider variant="middle" className={classes.divider} />
            </Grid>
            <Typography variant="body1" className={classes.textColor} >
                *Para agregar más de dos iniciadores debes de contratar uno de nuestros <b><Link className={classes.textColor} href="#" >paquetes Nexum.</Link></b>
            </Typography>
        </Grid>
    );

    return (
        <Grid container justifyContent="center" className={classes.root} >
            {/* Title Grid */}
            <Grid item xs={12} >
                <Typography
                    variant="h5"
                    className={classes.textColor}
                    style={{ fontWeight: 'bold', marginBottom: '2rem' }}
                >
                    {'Crea tu primer empresa'}
                </Typography>
            </Grid>
            {/* Data Grid */}
            <Grid container item xs={12} justifyContent="flex-start" spacing={1} className={classes.textColor} >
                <Grid item xs={12} container >
                    <Typography
                        variant="body1"
                        className={classes.textColor}
                    >
                        {'Agrega iniciadores a tu empresa'}
                    </Typography>
                </Grid>
                <Grid item xs={12} container className={classes.gridContainer} >
                    <Typography
                        variant="subtitle1"
                        className={classes.textColor}
                    >
                        {'Usuario'}
                    </Typography>
                </Grid>
                <Grid item xs={12} container alignItems="center" spacing={1} >
                    <Grid item xs={12} sm={10} >
                        <WBTextField
                            id="textfield-user"
                            placeholder="Correo"
                            variant="outlined"
                            fullWidth
                            size="small"
                            name="email"
                            InputProps={{
                                className: classes.txtField,
                            }}
                            InputLabelProps={{
                                className: classes.textColor
                            }}
                            value={newMember.email}
                            onChange={(e) => {
                                handleNewMember(e);
                            }}
                            error={!validateEmail(newMember.email)}
                            helperText={validateEmail(newMember.email) ? null : 'Correo no valido'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} >
                        <OutlinedButton
                            variant="outlined"
                            color="primary"
                            fullWidth
                            size="small"
                            disabled={(newMember.email == '' || groupMembers.length >= initiatiorLimit || validateEmail(newMember.email) == false ) ? true : false}
                            onClick={() => addNewMember()}
                        >
                            {'Agregar'}
                        </OutlinedButton>
                    </Grid>
                    {groupMembers.slice(1).map((member, index) => {
                        return (
                            <Box key={index} className={classes.boxInfoMember}>
                                <Grid container >
                                    {width < theme.breakpoints.values.sm ?
                                        <Grid item container xs={10} justifyContent="flex-start">
                                            <Grid container item xs={12} justifyContent="flex-start">
                                                <Typography variant="subtitle1" className={classes.roleMember}>{member.role}</Typography>
                                            </Grid>
                                            <Grid container item xs={12} justifyContent="flex-start">
                                                <Typography variant="body1" className={classes.emailMember}>{member.email}</Typography>
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid item container xs={10} justifyContent="flex-start">
                                            <Grid container item xs={3} sm={6} justifyContent="flex-start">
                                                <Typography variant="body1" className={classes.emailMember}>{member.email}</Typography>
                                            </Grid>
                                            <Grid container item xs sm={6} justifyContent="flex-start">
                                                <Typography variant="body1" className={classes.roleMember}>{member.role}</Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    <Grid item container xs={2}
                                        justifyContent="flex-end"
                                    >
                                        <IconButton
                                            className={classes.deleteIcon}
                                            onClick={() => {
                                                removeMember(index);
                                            }}
                                            size="small"
                                        >
                                            <CloseIcon className={classes.iconColor} fontSize="small" />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Box>
                        );
                    })}
                </Grid>
            </Grid>
            {groupMembers.length > 1 ? gridBuySignatures : null}
            <Grid
                item
                xs={12}
                container
                justifyContent="center"
                spacing={1}
                className={classes.buttons}
            >
                <Grid item>
                    <OutlinedButton
                        id="button-cancel"
                        variant="outlined"
                        color="primary"
                        onClick={prevStep}
                    >
                        {'Volver'}
                    </OutlinedButton>
                </Grid>
                <Grid item>
                    <Button
                        id="button-confirm"
                        variant="contained"
                        color="secondary"
                        className={classes.buttonWhite}
                        onClick={createGroup}
                    >
                        {'Crear'}
                    </Button>
                </Grid>
            </Grid>
            {/* Notes Grid */}
            <Grid item xs={12} container >
                <Grid item xs={12} >
                    <Divider variant="middle" style={{ marginTop: 15, marginBottom: 30 }} />
                </Grid>
                <Typography variant="body1" className={classes.textColor} >
                    <b>Iniciador:</b> Puede crear, enviar y exportar documentos.
                </Typography>
            </Grid>
        </Grid>
    );
    // View Ends
}

export default CreateGroupMembers;
