import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import { DropzoneDialogBase } from "react-mui-dropzone";
import useStyles, { OutlinedButton, OutlinedToggleBtn, WBTextField } from "./CreateGroupStyles";
import { ToggleButtonGroup } from '@mui/material';
import { Fragment } from "react";

// temporary data starts
const country = [
  { value: 'mexico', label: 'Mexico' },
  { value: 'usa', label: 'Estados Unidos' },
  { value: 'japan', label: 'Japon' }
];
const cities = [
  { value: 'chihuahua', label: 'Chihuahua' },
  { value: 'playaCarmen', label: 'Playa del Carmen' },
  { value: 'monterrey', label: 'Monterrey' }
];
const state = [
  { value: 'chihuahua', label: 'Chihuahua' },
  { value: 'cancun', label: 'Cancun' },
  { value: 'nuevoleon', label: 'Nuevo Leon' }
];
// temporary data ends

const CreateGroup = ({
  nextStep,
  handleGroupChange,
  handleGroupTypeChange,
  group,
  documents,
  handleDocumentChange,
  toLogin
}) => {

  const [actaOpen, setActaOpen] = useState(false);
  const [cartaOpen, setCartaOpen] = useState(false);
  const [constanciaOpen, setConstanciaOpen] = useState(false);
  const [cedulaOpen, setCedulaOpen] = useState(false);

  // Controller Logic Start
  const handleOnSubmit = (e) => {
    e.preventDefault();
    nextStep();
  };
  // Controller Logic End

  // View Logic Start
  const classes = useStyles();
  const fisicaDocs = (
    <Grid id="grid-cedula" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
      <Grid item xs={12} container >
        <Typography variant="subtitle1" className={classes.textColor}>Cédula de identificación fiscal</Typography>
      </Grid>
      <Grid item xs={12} container className={classes.gridContainer} >
        <OutlinedButton
          id="button-cedula"
          variant="outlined"
          color="primary"
          onClick={() => setCedulaOpen(true)}
        >
          {'Subir cedula'}
        </OutlinedButton>
      </Grid>
    </Grid>
  );
  const moralDocs = (
    <Fragment >
      <Grid id="grid-acta-constitutiva" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
        <Grid item xs={12} container >
          <Typography variant="subtitle1" className={classes.textColor}>Acta constitutiva</Typography>
        </Grid>
        <Grid item xs={12} container className={classes.gridContainer} >
          <OutlinedButton
            id="button-acta"
            variant="outlined"
            color="primary"
            onClick={() => setActaOpen(true)}
          >
            {'Subir acta constitutiva'}
          </OutlinedButton>
        </Grid>
      </Grid>
      <Grid id="grid-carta" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
        <Grid item xs={12} container >
          <Typography variant="subtitle1" className={classes.textColor}>Carta poder</Typography>
        </Grid>
        <Grid item xs={12} container className={classes.gridContainer} >
          <OutlinedButton
            id="button-carta"
            variant="outlined"
            color="primary"
            onClick={() => setCartaOpen(true)}
          >
            {'Subir carta poder'}
          </OutlinedButton>
        </Grid>
      </Grid>
      <Grid id="grid-constancia" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
        <Grid item xs={12} container >
          <Typography variant="subtitle1" className={classes.textColor}>Constancia de situación fiscal</Typography>
        </Grid>
        <Grid item xs={12} container className={classes.gridContainer} >
          <OutlinedButton
            id="button-constancia"
            variant="outlined"
            color="primary"
            onClick={() => setConstanciaOpen(true)}
          >
            {'Subir constancia'}
          </OutlinedButton>
        </Grid>
      </Grid>
    </Fragment>
  );

  // Main View

  return (
    <form onSubmit={handleOnSubmit} >
      <Grid container justifyContent="center" className={classes.root} >
        {/* Title Grid */}
        <Grid item xs={12} >
          <Typography
            variant="h5"
            className={classes.textColor}
            style={{ fontWeight: 'bold', marginBottom: '2rem' }}
          >
            {'Crea tu primer empresa'}
          </Typography>
        </Grid>
        {/* Data Grid Starts */}
        <Grid container item xs={12} justifyContent="flex-start" spacing={1} className={classes.textColor} >
          {/* Company Type Grid Starts */}
          <Grid id='company-type-grid' item xs={12} container justifyContent="flex-start" >
            <Grid item xs={12} container style={{ paddingBottom: '.3rem' }}>
              <Typography variant="subtitle1" className={classes.textColor} >{'Tipo de empresa'}</Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-start" >
              <ToggleButtonGroup
                value={group.type}
                exclusive
                onChange={handleGroupTypeChange}
              >
                <OutlinedToggleBtn value="fisica" >
                  {'Física'}
                </OutlinedToggleBtn>
                <OutlinedToggleBtn value="moral" >
                  {'Moral'}
                </OutlinedToggleBtn>
              </ToggleButtonGroup>
            </Grid>
          </Grid>
          {/* Company Type Grid Ends */}
          {/* Form Grid Starts */}
          <Grid id="grid-name" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Nombre de empresa</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                required
                id="textfield-name"
                placeholder="Nombre"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                value={group.name}
                name="name"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid id="grid-country" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>País</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                id="textfield-country"
                required
                select
                fullWidth
                size="small"
                name="country"
                value={group.country}
                onChange={(e) => {
                  handleGroupChange(e);
                }}
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                defaultValue=""
              >
                <option value="" disabled>{'Favor de escoger una opción'}</option>
                {country.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </WBTextField>
            </Grid>
          </Grid>
          <Grid id="grid-state" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Estado</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                id="textfield-state"
                required
                select
                fullWidth
                size="small"
                value={group.state}
                defaultValue=""
                name="state"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value="" disabled>{'Favor de escoger una opción'}</option>
                {state.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </WBTextField>
            </Grid>
          </Grid>
          <Grid id="grid-city" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Ciudad</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                id="textfield-city"
                required
                select
                fullWidth
                size="small"
                value={group.city}
                defaultValue=""
                name="city"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option value="" disabled>{'Favor de escoger una opción'}</option>
                {cities.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </WBTextField>
            </Grid>
          </Grid>
          <Grid id="grid-street" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Calle</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                required
                id="textfield-street"
                placeholder="Calle"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                value={group.street}
                name="street"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid id="grid-address-number" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Número</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                required
                id="textfield-address-number"
                placeholder="12343"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                value={group.addressNum}
                name="addressNum"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid id="grid-zipcode" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Código postal</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer}>
              <WBTextField
                required
                id="textfield-zipcode"
                placeholder="11111"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                value={group.zipCode}
                name="zipCode"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
              />
            </Grid>
          </Grid>
          <Grid id="grid-district" item xs={12} sm={6} container justifyContent="flex-start" className={classes.gridTextField} >
            <Grid item xs={12} container >
              <Typography variant="subtitle1" className={classes.textColor}>Colonia</Typography>
            </Grid>
            <Grid item xs={12} className={classes.gridContainer} >
              <WBTextField
                required
                id="textfield-district"
                placeholder="Colonia"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  className: classes.txtField,
                }}
                InputLabelProps={{
                  className: classes.textColor
                }}
                value={group.district}
                name="district"
                onChange={(e) => {
                  handleGroupChange(e);
                }}
              />
            </Grid>
          </Grid>
          {group.type === 'fisica' ? fisicaDocs : moralDocs}
          {/* Form Grid Ends */}
        </Grid>
        {/* Data Grid Ends */}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          spacing={1}
          className={classes.buttons}
        >
          <Grid item>
            <OutlinedButton
              id="button-cancel"
              variant="outlined"
              color="primary"
              onClick={() => toLogin()}
            >
              {'Omitir'}
            </OutlinedButton>
          </Grid>
          <Grid item>
            <Button
              id="button-confirm"
              variant="contained"
              color="secondary"
              className={classes.buttonWhite}
              type="submit"
            >
              {'Siguiente'}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {group.type === 'fisica' ?
        <DropzoneDialogBase
          dialogTitle={"Cédula de identificación fiscal"}
          acceptedFiles={[".pdf, .docx"]}
          filesLimit={1}
          fileObjects={documents.cedula}
          cancelButtonText={"cancelar"}
          submitButtonText={"aceptar"}
          maxFileSize={10_000_000}
          open={cedulaOpen}
          onAdd={(file) => {
            handleDocumentChange(file, "cedula");
          }}
          onDelete={(file) => {
            handleDocumentChange([], "cedula");
          }}
          onClose={() => {
            handleDocumentChange([], "cedula");
            setCedulaOpen(false)
          }}
          onSave={() => {
            setCedulaOpen(false);
          }}
          showPreviews={true}
          showFileNamesInPreview={true}
        /> :
        <Fragment >
          <DropzoneDialogBase
            dialogTitle={"Acta constitutiva"}
            acceptedFiles={[".pdf, .docx"]}
            filesLimit={1}
            fileObjects={documents.acta}
            cancelButtonText={"cancelar"}
            submitButtonText={"aceptar"}
            maxFileSize={10_000_000}
            open={actaOpen}
            onAdd={(file) => {
              handleDocumentChange(file, "acta");
            }}
            onDelete={(file) => {
              handleDocumentChange([], "acta");
            }}
            onClose={() => {
              handleDocumentChange([], "acta");
              setActaOpen(false)
            }}
            onSave={() => {
              setActaOpen(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
          <DropzoneDialogBase
            dialogTitle={"Carta poder"}
            acceptedFiles={[".pdf, .docx"]}
            filesLimit={1}
            fileObjects={documents.carta}
            cancelButtonText={"cancelar"}
            submitButtonText={"aceptar"}
            maxFileSize={10_000_000}
            open={cartaOpen}
            onAdd={(file) => {
              handleDocumentChange(file, "carta");
            }}
            onDelete={(file) => {
              handleDocumentChange([], "carta");
            }}
            onClose={() => {
              handleDocumentChange([], "carta");
              setCartaOpen(false)
            }}
            onSave={() => {
              setCartaOpen(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
          <DropzoneDialogBase
            dialogTitle={"Constancia de situación fiscal"}
            acceptedFiles={[".pdf, .docx"]}
            filesLimit={1}
            fileObjects={documents.constancia}
            cancelButtonText={"cancelar"}
            submitButtonText={"aceptar"}
            maxFileSize={10_000_000}
            open={constanciaOpen}
            onAdd={(file) => {
              handleDocumentChange(file, "constancia");
            }}
            onDelete={(file) => {
              handleDocumentChange([], "constancia");
            }}
            onClose={() => {
              handleDocumentChange([], "constancia");
              setConstanciaOpen(false)
            }}
            onSave={() => {
              setConstanciaOpen(false);
            }}
            showPreviews={true}
            showFileNamesInPreview={true}
          />
        </Fragment>
      }
    </form>
  );
  // View Logic End
};

export default CreateGroup;
