import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    [theme.breakpoints.only('xs')]: {
      backgroundColor: '#041854'
    }
  },
  buttons: {
    marginTop: "20px"
  },
  buttonWhite: {
    textTransform: 'none',
    fontFamily: 'Roboto',
    fontSize: 12,
    color: 'white'
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 14,
  },
  textColor: {
    color: 'black',
    [theme.breakpoints.only('xs')]: {
      color: 'white'
    }
  },
  txtField: {
    height: 50,
    fontFamily: 'Roboto',
    fontSize: 14,
    color: 'black',
    [theme.breakpoints.only('xs')]: {
      color: 'white'
    }
  },
}));

export const WBTextField = withStyles(theme => ({
  root: {
    '& label.Mui-focused': {
      color: '#041854',
      [theme.breakpoints.only('xs')]: {
        color: 'white'
      }
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#041854',
      [theme.breakpoints.only('xs')]: {
        borderBottomColor: 'white'
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
        }
      },
      '&:hover fieldset': {
        borderColor: '#041854',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
      }
      },
      '&.Mui-focused fieldset': {
        borderColor: '#041854',
        [theme.breakpoints.only('xs')]: {
          borderColor: 'white'
      }
      },
    },
  },
}))(TextField);

export default useStyles
