import * as React from "react";
import { SvgIcon } from '@mui/material'

function SvgIconCrearDocumento(props) {
  return (
    <SvgIcon >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <switch>
        <g>
          <path d="M4.1 18.8c0 .4.3.7.7.7h5.5c.4 0 .7-.3.7-.7s-.3-.7-.7-.7H4.8c-.4 0-.7.3-.7.7zM13.1 15.5H4.8c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h8.3c.4 0 .7-.3.7-.7 0-.4-.3-.7-.7-.7zM20 6.5h-2.1v-2c0-.4-.3-.7-.7-.7s-.7.3-.7.7v2h-2.1c-.4 0-.7.3-.7.7s.3.7.7.7h2.1V10c0 .4.3.7.7.7s.7-.3.7-.7V7.8H20c.4 0 .7-.3.7-.7s-.3-.6-.7-.6z" />
          <path d="M24 7.2C24 3.4 20.9.3 17.1.3c-.3 0-.6 0-.8.1-.1 0-.3-.1-.4-.1H6.2c-.2-.1-.4 0-.5.1L.2 6c-.1.1-.2.3-.2.4v15.3c0 1.1.9 2.1 2.1 2.1h13.8c1.1 0 2.1-.9 2.1-2.1V14c3.3-.4 6-3.3 6-6.8zM5.5 2.6v2.5c0 .4-.3.7-.7.7H2.4l3.1-3.2zm10.3 19.8H2.1c-.4 0-.7-.3-.7-.7V7.1h3.4c1.1 0 2.1-.9 2.1-2.1V1.6H13c-1.7 1.3-2.8 3.3-2.8 5.5 0 1 .2 2 .6 2.8H4.7c-.3 0-.6.3-.6.7 0 .4.3.7.6.7h6.9c.4.5.9 1 1.4 1.4H4.8c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h8.3c.4 0 .7-.3.7-.7v-.2c.8.5 1.8.8 2.8.8v7.7c-.1.4-.4.7-.8.7zm1.3-9.8c-3 0-5.4-2.4-5.4-5.4 0-3 2.4-5.4 5.4-5.4s5.4 2.4 5.4 5.4c0 3-2.4 5.4-5.4 5.4z" />
        </g>
      </switch>
    </svg>
    </SvgIcon>
    
  );
}

export default SvgIconCrearDocumento;
