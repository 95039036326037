import { db } from "../Firebase";
import { addMembersToGroup } from "./GroupUserData";
import { saveGroupDocumentFirebase } from './storage';

const collectionName = "GroupsData";
const groupDataRef = db.collection(collectionName);

const objectHasProperties = (object) => {
    for (const prop in object) {
        if (object.hasOwnProperty(prop)) {
            return true;
        }
    }
    return false;
}

export const createNewGroup = async (userId, groupInfo, groupMembers, documents) => {
    let documentUrls = {};

    groupDataRef.add({
        Type: groupInfo.type,
        Name: groupInfo.name,
        Country: groupInfo.country,
        State: groupInfo.state,
        City: groupInfo.city,
        Street: groupInfo.street,
        District: groupInfo.district,
        AddressNum: groupInfo.addressNum,
        ZipCode: groupInfo.zipCode, 
        creatorId: userId
    })
    .then(async (docRef) => {
        addMembersToGroup(docRef.id, groupMembers);
        //Upload documents to firestore and update url object
        if (groupInfo.type == 'moral') {
            if (documents.acta.length > 0) {
                const file = documents.acta[0].file;
                try {
                    const { url } = await saveGroupDocumentFirebase(docRef.id, file, 'acta');
                    documentUrls = {...documentUrls, Acta: url};
                } catch (error) {
                    console.log(error);
                }
            }
            if (documents.carta.length > 0) {
                const file = documents.carta[0].file;
                try {
                    const { url } = await saveGroupDocumentFirebase(docRef.id, file, 'carta');
                    documentUrls = {...documentUrls, Carta: url};
                } catch (error) {
                    console.log(error);
                }
            }
            if (documents.constancia.length > 0) {
                const file = documents.constancia[0].file;
                try {
                    const { url } = await saveGroupDocumentFirebase(docRef.id, file, 'constancia');
                    documentUrls = {...documentUrls, Constancia: url};
                } catch (error) {
                    console.log(error);
                }
            }
        } else if (groupInfo.type == 'fisica') {
            if (documents.cedula.length > 0) {
                const file = documents.cedula[0].file;
                try {
                    const { url } = await saveGroupDocumentFirebase(docRef.id, file, 'cedula');
                    documentUrls = {...documentUrls, Cedula: url};
                } catch (error) {
                    console.log(error);
                }
            }
        }
        //Update group with document urls
        if (objectHasProperties(documentUrls)) {
            groupDataRef.doc(docRef.id).update({
                ...documentUrls
            })
            .then(() => {
            })
            .catch((error) => {
                // The document probably doesn't exist.
                console.error("Error updating urls: ", error);
            });
        }

    })
    .catch((error) => {
        console.error("Error adding document: ", error);
    });
};