import React, { Suspense, lazy } from 'react';
import { AuthProvider } from './Contexts/AuthContext';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import ProtectedUserRoute from 'Helpers/ProtectedUserRoute';
import ProtectedAdminRoute from 'Helpers/ProtectedAdminRoute';
import ProtectedGuestRoute from 'Helpers/ProtectedGuestRoute';
import './App.css';
import Auth from './components/Auth/Auth';

import Loading from './components/Loading';

const ResetPassword = lazy(() =>
  import('./components/Auth/ResetPassword/ResetPassword')
);

const AddUnits = lazy(() => import('./components/Admin/AddUnits/AddUnits'));
const NexumPackages = lazy(() =>
  import('./components/Auth/NexumPackages/NexumPackages')
);
const DocumentView = lazy(() =>
  import(
    './components/Admin/DocumentEditor/DocumentList/DocumentView/DocumentView'
  )
);
const DocumentDetails = lazy(() =>
  import(
    './components/Admin/DocumentEditor/DocumentList/DocumentDetails/DocumentDetails'
  )
);
const DocumentCreation = lazy(() =>
  import('./components/Admin/DocumentCreation/DocumentCreation')
);
const Dashboard = lazy(() =>
  import('./components/Admin/Home/Dashboard/Dashboard')
);
const DocumentEditor = lazy(() =>
  import('./components/Admin/DocumentEditor/DocumentEditor')
);
const AccountsView = lazy(() =>
  import('./components/Administrator/AccountsView/AccountsView')
);
const RequestsView = lazy(() =>
  import('./components/Administrator/RequestsView/RequestsView')
);
const AccountConfig = lazy(() =>
  import('./components/Admin/AccountConfig/AccountConfig')
);
const UserProfile = lazy(() =>
  import('./components/Admin/User/UserProfile/UserProfile')
);
const PageNotFound = lazy(() =>
  import('./components/PageNotFound/PageNotFound')
);
const Notifications = lazy(() =>
  import('components/Admin/Notifications/Notifications')
);
const Logs = lazy(() => import('components/Admin/Service/Logs'));
const Search = lazy(() => import('components/Admin/Home/Search/Search'));
const PaymentPending = lazy(() =>
  import('components/PaymentViews/Pending/PaymentPending')
);
const PaymentError = lazy(() =>
  import('components/PaymentViews/Error/PaymentError')
);
const PaymentSuccess = lazy(() =>
  import('components/PaymentViews/Success/PaymentSuccess')
);
const LoadDocument = lazy(() =>
  import('components/Administrator/LoadDocument/LoadDocument')
);
// const PDFTrial = lazy(() =>
//   import('components/Administrator/PDFTrial/PDFTrial')
// );

const App = () => {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route path="/agrega_firmas" element={<AddUnits />} />
              <Route path="/login" element={<Auth />} />
              <Route path="/login/restablecer" element={<ResetPassword />} />
              <Route path="/paquetes" element={<NexumPackages />} />
              <Route path="/cargando" element={<Loading />} />
              <Route path="/pago_pendiente" element={<PaymentPending />} />
              <Route path="/pago_error" element={<PaymentError />} />
              <Route path="/pago_exitoso" element={<PaymentSuccess />} />

              <Route path="/logs" element={<ProtectedUserRoute><Logs /></ProtectedUserRoute>} />
              <Route path="/" element={<ProtectedUserRoute><Dashboard /></ProtectedUserRoute>} />
              <Route path="/crea_documento" element={<ProtectedUserRoute><DocumentCreation /></ProtectedUserRoute>} />
              <Route path="/edita_documento" element={<ProtectedUserRoute><DocumentEditor typeTable="toEdit" /></ProtectedUserRoute>} />
              <Route path="/firma_documento" element={<ProtectedUserRoute><DocumentEditor typeTable="toSign" /></ProtectedUserRoute>} />
              <Route path="/edit/document/:id" element={<ProtectedUserRoute><DocumentDetails /></ProtectedUserRoute>} />
              <Route path="/configuración_cuenta" element={<ProtectedUserRoute><AccountConfig /></ProtectedUserRoute>} />
              <Route path="/notificaciones" element={<ProtectedUserRoute><Notifications /></ProtectedUserRoute>} />
              <Route path="/buscar" element={<ProtectedUserRoute><Search /></ProtectedUserRoute>} />
              <Route path="/perfil" element={<ProtectedUserRoute><UserProfile /></ProtectedUserRoute>} />

              <Route path="/sign/document/:id" element={<ProtectedGuestRoute><DocumentView /></ProtectedGuestRoute>} />

              {/* Rutas protegidas para administradores */}
              <Route path="/admin/load_document" element={<ProtectedAdminRoute><LoadDocument /></ProtectedAdminRoute>} />
              <Route path="/admin/accounts" element={<ProtectedAdminRoute><AccountsView /></ProtectedAdminRoute>} />
              <Route path="/admin/requests" element={<ProtectedAdminRoute><RequestsView /></ProtectedAdminRoute>} />

              {/* Ruta para 404 - Not Found */}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </Suspense>
        </AuthProvider>
      </Router>
    </div>
  );
};

export default App;
