import { SAVE_USER_DATA, LOGOUT_USER } from "./actionTypes";

export const saveUserData = (user) => {
  return {
    type: SAVE_USER_DATA,
    payload: user,
  };
};

export const logOutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
