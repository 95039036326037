import { storage } from '../Firebase';
import { formatStorageSpaceToMegabytes } from '../formatStorage';
import {
  addDocumentOtherFiles,
  deleteDocumentOtherFiles
} from './DocumentData';

export const getStorageSpaceUsedByUserId = async (userId) => {
  let path = `documents/${userId}`;
  const ref = storage.ref().child(path);
  const dataCollection = await ref.listAll();
  const sizeFiles = await Promise.all(
    dataCollection.prefixes.map(async (resFolder) => {
      let dataFolder = await resFolder.listAll();
      return await Promise.all(
        dataFolder.items.map(async (resFile) => {
          let meta = await resFile.getMetadata();
          return meta.size;
        })
      );
    })
  );
  const totalSize = sizeFiles
    .map((arr) => arr.reduce((sum, size) => (sum += size), 0))
    .reduce((sum, size) => (sum += size), 0);

  let megabytesUsed = formatStorageSpaceToMegabytes(totalSize);
  return megabytesUsed;
};

const saveFile = async (fileToSave, path) => {
  let metadata = {
    contentType: fileToSave.type
  };
  const ref = storage.ref().child(path);
  await ref.put(fileToSave, metadata);
  const url = await ref.getDownloadURL();
  return url;
};

const deleteFile = async (path) => {
  let ref = storage.ref().child(path);
  await ref.delete();
};

const deleteFileFromURL = async (url) => {
  let ref = storage.refFromURL(url);
  const deletedFile = await ref.delete().catch((error) => {
    throw error;
  });
  return deletedFile;
};

export const saveMainDocumentFirebase = async (
  userId,
  fileToSave,
  storageLimit
) => {
  if (fileToSave instanceof File) {
    let freeStorage = await checkStorageSpace(
      fileToSave.size,
      storageLimit,
      userId
    );
    if (freeStorage) {
      let name = fileToSave.name;
      let time = Date.now();
      let path = generatePath(userId, 'docs', time, name);
      let url = await saveFile(fileToSave, path);
      return {
        url: url,
        name: name,
        userId: userId,
        time: time
      };
    } else {
      throw 'Not enough free space';
    }
  }
};

export const deleteMainDocumentFirebase = async (userId, fileToDelete) => {
  let path = generatePath(userId, 'docs', fileToDelete.time, fileToDelete.name);
  await deleteFile(path);
};

export const saveDocumentAttachedFileFirebase = async (
  userId,
  docId,
  fileToSave,
  storageLimit,
  fileType
) => {
  const freeStorage = await checkStorageSpace(
    fileToSave.size,
    storageLimit,
    userId
  );
  if (freeStorage) {
    const name = fileToSave.name;
    const time = Date.now();
    const path = generatePath(userId, fileType, time, name);
    const url = await saveFile(fileToSave, path).catch((error) => {
      throw error;
    });

    // Esto esta para cuando apenas se esta creando el documento no
    // busque un id inexistente
    if (docId) {
      const fileObject = makeFileObject(url, name, time);
      await addDocumentOtherFiles(docId, fileType, fileObject).catch(
        (error) => {
          throw error;
        }
      );
    }

    return {
      url: url,
      name: name,
      time: time
    };
  } else {
    throw 'Not enough free space';
  }
};

export const deleteExtraDocFirebase = async (userId, docId, fileToDelete) => {
  let path = generatePath(
    userId,
    'extraDocs',
    fileToDelete.time,
    fileToDelete.name
  );
  await deleteFile(path)
    .then(() => {
      if (docId) {
        return deleteDocumentOtherFiles(docId, 'extraDocs', fileToDelete);
      }
    })
    .catch((error) => {
      console.log(error.message);
      throw error;
    });
};

export const deleteAddendumFirebase = async (userId, docId, fileToDelete) => {
  const path = generatePath(
    userId,
    'addendums',
    fileToDelete.time,
    fileToDelete.name
  );
  await deleteFile(path)
    .then(() => {
      if (docId) {
        return deleteDocumentOtherFiles(docId, 'addendums', fileToDelete);
      }
    })
    .catch((error) => {
      console.log(error.message);
      throw error;
    });
};

const saveSignedFile = async (fileToSave, path) => {
  const ref = storage.ref().child(path);
  await ref.putString(fileToSave, 'base64', { contentType: 'application/pdf' });
  const url = await ref.getDownloadURL();
  return url;
};

export const saveSignedDocumentFirebase = async (
  userId,
  fileToSave,
  docName
) => {
  let name = docName;
  let time = Date.now();
  let path = generatePath(userId, 'docs', time, name);
  let url = await saveSignedFile(fileToSave, path);
  return {
    url: url,
    name: name,
    userId: userId,
    time: time
  };
};

const saveXMLFile = async (fileToSave, path) => {
  const ref = storage.ref().child(path);
  await ref.putString(fileToSave);
  const url = await ref.getDownloadURL();
  return url;
};

export const saveXMLFirebase = async (userId, fileToSave, docName) => {
  let name = docName;
  let time = Date.now();
  let path = generatePath(userId, 'docs', time, name);
  let url = await saveXMLFile(fileToSave, path);
  return {
    url: url,
    name: name,
    userId: userId,
    time: time
  };
};

const saveIDPictureFile = async (fileToSave, path) => {
  const ref = storage.ref().child(path);
  const metadata = {
    contentType: fileToSave.contentType
  };
  await ref.put(fileToSave, metadata);
  const url = await ref.getDownloadURL();
  return url;
};

export const saveIDPictureFirebase = async (userId, fileToSave, docName) => {
  let name = docName;
  let time = Date.now();
  let path = generatePath(userId, 'signerPictures', time, name);
  let url = await saveIDPictureFile(fileToSave, path);
  return {
    url: url,
    name: name,
    userId: userId,
    time: time
  };
};

const saveIDDocFile = async (fileToSave, path) => {
  const ref = storage.ref().child(path);
  const metadata = {
    contentType: fileToSave.contentType
  };
  await ref.put(fileToSave, metadata);
  const url = await ref.getDownloadURL();
  return url;
};

export const saveIDDocFirebase = async (userId, fileToSave, docName) => {
  let name = docName;
  let time = Date.now();
  let path = generatePath(userId, 'signerIDDocs', time, name);
  let url = await saveIDDocFile(fileToSave, path);
  return {
    url: url,
    name: name,
    userId: userId,
    time: time
  };
};

export const deleteIDPictureByURL = async (url) => {
  const deletedIDPicture = await deleteFileFromURL(url);
  return deletedIDPicture;
};

export const saveGroupDocumentFirebase = async (
  groupId,
  fileToSave,
  docType
) => {
  let name = fileToSave.name;
  let time = Date.now();
  let path = generateGroupPath(groupId, docType, time, name);
  let url = await saveFile(fileToSave, path);
  return {
    url: url,
    name: name,
    groupId: groupId,
    time: time
  };
};

export const saveDocumentUser = async (userId, fileToSave, docType) => {
  let name = fileToSave.name;
  let time = Date.now();
  let path = generateUserPath(userId, docType, time, name);
  let url = await saveFile(fileToSave, path);
  return {
    url: url,
    name: name,
    time: time
  };
};

export const deleteDocumentUser = async (userId, fileToDelete, docType) => {
  let path = generateUserPath(
    userId,
    docType,
    fileToDelete.time,
    fileToDelete.name
  );
  await deleteFile(path);
};

const generatePath = (userId, type, time, fileName) => {
  let path = `documents/${userId}/${type}/${time}-${fileName}`;
  return path;
};

const generateGroupPath = (groupId, type, time, fileName) => {
  let path = `groups/${groupId}/${type}/${time}-${fileName}`;
  return path;
};

const generateUserPath = (userId, type, time, fileName) => {
  let path = `users/${userId}/${type}/${time}-${fileName}`;
  return path;
};

const checkStorageSpace = async (sizeNewFile, storageLimit, userId) => {
  const storageUsed = await getStorageSpaceUsedByUserId(userId);
  let sizeNewFileMegabytes = formatStorageSpaceToMegabytes(sizeNewFile);
  let totalStorage = parseFloat(storageUsed) + parseFloat(sizeNewFileMegabytes);
  if (parseFloat(totalStorage) <= parseFloat(storageLimit)) {
    return true;
  } else {
    return false;
  }
};

const makeFileObject = (url, name, time) => {
  return {
    url: url,
    name: name,
    time: time
  };
};
