import * as React from "react";
import { SvgIcon } from '@mui/material'

function SvgIconInicio(props) {
  return (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <switch>
          <g>
            <path d="M9.5 8h-8C.7 8 0 7.3 0 6.5v-5C0 .7.7 0 1.5 0h8c.8 0 1.5.7 1.5 1.5v5c0 .8-.7 1.5-1.5 1.5zm-8-7c-.3 0-.5.2-.5.5v5c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5v-5c0-.3-.2-.5-.5-.5h-8zM9.5 24h-8C.7 24 0 23.3 0 22.5v-11c0-.8.7-1.5 1.5-1.5h8c.8 0 1.5.7 1.5 1.5v11c0 .8-.7 1.5-1.5 1.5zm-8-13c-.3 0-.5.2-.5.5v11c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5v-11c0-.3-.2-.5-.5-.5h-8zM22.5 24h-8c-.8 0-1.5-.7-1.5-1.5v-5c0-.8.7-1.5 1.5-1.5h8c.8 0 1.5.7 1.5 1.5v5c0 .8-.7 1.5-1.5 1.5zm-8-7c-.3 0-.5.2-.5.5v5c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5v-5c0-.3-.2-.5-.5-.5h-8zM22.5 14h-8c-.8 0-1.5-.7-1.5-1.5v-11c0-.8.7-1.5 1.5-1.5h8c.8 0 1.5.7 1.5 1.5v11c0 .8-.7 1.5-1.5 1.5zm-8-13c-.3 0-.5.2-.5.5v11c0 .3.2.5.5.5h8c.3 0 .5-.2.5-.5v-11c0-.3-.2-.5-.5-.5h-8z" />
          </g>
        </switch>
      </svg>
    </SvgIcon>

  );
}

export default SvgIconInicio;
